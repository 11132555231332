import {
    FiCheck as Check,
} from "react-icons/fi";

import {
    BiMenu as Menu
} from "react-icons/bi";

import {
    RiArrowLeftSLine as LeftArrow,
    RiArrowRightSLine as RightArrow,
    RiArrowDownSLine as DownArrow,
    RiArrowUpSLine as UpArrow,
    RiBarChartFill as Chart,
    RiAddFill as Add
} from 'react-icons/ri';

import {
    MdPayment as Payments,
    MdPending as Pending
} from 'react-icons/md';

import {
    BsXCircleFill as Failed
} from 'react-icons/bs';

import { 
    HiDotsVertical as CounterMenu,
} from 'react-icons/hi';

import {
    CgArrowsExchangeAlt as Exchange
} from 'react-icons/cg';


import {
    AiOutlineFall as LineFall,
    AiOutlineRise as LineRise,
    AiFillApi as Providers,
    AiOutlineMenuUnfold as MenuUnfold,
    AiOutlineMenuFold as MenuFold,
    AiFillEye as Eye
} from 'react-icons/ai';

import {
    GrServices as Services
} from 'react-icons/gr';

import {
    IoIosCheckmarkCircle as Success,
} from 'react-icons/io';

import {
    AgDashboard as Dashboard,
    AgAccounting as Accounting,
    AgCompliance as Compliance,
    AgSettings as Settings,
    AgDisbursement as Disbursement,
    AgTransaction as Transaction,
    AgUser as User,
    AgUsers as Users,
    AgRevenue as Revenue,
    AgRefresh as Refresh,
    AgTransactionDArrow as TransactionDArrow,
    AgGateway as Gateway,
    AgNotification as Notification,
    AgChat as Chat,
    AgChevronDown as ChevronDown,
    AgStatistics as Statistics,
    AgComment as Comment,
    AgSearch as Search,
    AgFilter as Filter,
    AgTimes as Times,
    AgAddUser as AddUser,
    AgSorting as Sorting,
    AgAudit as Audit,
    AgEnvelope as Envelope,
    AgLogout as Logout,
    AgClose as Close
} from '../components/ag-icons';



const Icons = {
    Add,
    Failed,
    Pending,
    Success,
    Eye,
    MenuFold,
    MenuUnfold,
    Services,
    Payments,
    Close,
    Audit,
    Sorting,
    AddUser,
    Times,
    Filter,
    Search,
    Check,
    Comment,
    Notification,
    Providers,
    Dashboard,
    Compliance,
    Transaction,
    Disbursement,
    Gateway,
    Statistics,
    User,
    Users,
    Refresh,
    Revenue,
    TransactionDArrow,
    Accounting,
    Settings,
    Chat,
    ChevronDown,
    LeftArrow,
    RightArrow,
    UpArrow,
    DownArrow,
    Menu,
    CounterMenu,
    Chart,
    Exchange,
    LineFall,
    LineRise,
    Envelope,
    Logout
}

export default Icons;