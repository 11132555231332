import { Button, Form, Input, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { Heading, VSpace } from "./styles";

import {
  customerActions,
  customerConstants
} from "../../state/customer";


const { addNewCustomer } = customerActions;



const user_types = [
  {
    name: 'USER',
  },
  {
    name: 'AGENT'
  },
  {
    name: 'SUPERAGENT'
  }
];

const AddNewCustomer = ({ customer_added, addNewCustomer, auth, visible, onCancel }) => {
  const [newCustomerAdded, startNewCustomerAdding] = useState(false);

  const onFinish = (values) => {
    const names = values?.name?.split(' ');
    
    const data = {...values, first_name: names[0]?? '', last_name: names[1]?? '', country: values?.country?? 'NG'};

    addNewCustomer(auth, data);

    setTimeout(() => {
      startNewCustomerAdding(true);
    }, 3000);
  };

  const { error, loading, status, message } = customer_added;

  useEffect(() => {
    if (!loading && newCustomerAdded && status?.toLowerCase() === 'success') {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }, [loading, status, message, newCustomerAdded]);

  return (
    <Modal title="New Customer" visible={visible} footer={null} closable={true} onCancel={onCancel}>
      <Form
        layout="vertical"
        name="customer_added"
        initialValues={{
          remember: true
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        {status === "error" && !loading && <Heading className='text-danger'>{error?.message}</Heading>}
        {status === "success" && newCustomerAdded && <Heading className='text-success'>{message}.   Updating customer history in 2secs...</Heading>}
        <VSpace />
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Name is required"
            }
          ]}
        >
          <Input />
        </Form.Item>
        <VSpace space="2px" />
        <Form.Item
          label="Email Address"
          name="email"
          rules={[
            {
              required: true,
              message: "Email address is required"
            }
          ]}
        >
          <Input />
        </Form.Item>
        <VSpace space="2px" />
        <Form.Item
          label="Mobile Number"
          name="phone_number"
          rules={[
            {
              required: true,
              message: 'Mobile Number is required'
            }
          ]}
        >
          <Input />
        </Form.Item>

        <VSpace space="2px" />
        <Form.Item
          label="Account Type"
          name="user_type"
          rules={[
            {
              required: true,
              message: 'Please select a valid account type'
            }
          ]}
        >
          <Select
            defaultValue=""
            placeholder="Select User Type"
          >
            {
              user_types?.map((user_type, index) => (
                <Select.Option key={index} value={user_type?.name}>{user_type?.name}</Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <VSpace />
        <Form.Item
        >
          <Button loading={loading} type="primary" htmlType="submit">
            Create Account
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};


const structuredSelector = createStructuredSelector({
  auth: state => state.auth,
  customer_added: state => state?.app[customerConstants.addNewCustomer] ?? {}
});

const mapDispatchToProps = dispatch => {
  return {
    addNewCustomer: (state, payload) => dispatch(addNewCustomer(state, payload))
  };
};

export default connect(structuredSelector, mapDispatchToProps)(AddNewCustomer);