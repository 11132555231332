
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { NavTab, TransactionHistoryTable } from '../../common/components';
import { Themes } from '../../common/constants';

import { Row, Col } from 'react-flexbox-grid';
import transactionData from './data';
import { Block } from '../../common/components/styles';
import { useLocation, useNavigate } from 'react-router-dom';

const TransactionList = (props) => {
    
    const location = useLocation();
    const {pathname} = location?? {};

    const navigate = useNavigate();

    const onTabChanged = (item) => {
        if (item.key) return false //remove later
        navigate(item.key);
    }

    return(
        <>
             <Row className='mt-1'>
                <Col x={12} sm={12} md={6}>
                    <NavTab 
                        defaultValue={pathname} 
                        data={transactionData.tab}  
                        onClick={onTabChanged}
                        className='nav-tab-container'
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Block variant='div' background={Themes.white} padding='24px' borderRadius='8px'>
                        <TransactionHistoryTable />
                    </Block>   
                </Col>
            </Row>   
        </>  
    );
}

const structuredSelector = createStructuredSelector({
    auth: (state) => state.auth,
  });
  
const mapDispatchToProps = (dispatch) => {
    return {
      
    };
};
  
export default connect(structuredSelector, mapDispatchToProps)(TransactionList);
  
