import { Link } from "react-router-dom";
import { Icons, Themes } from "../../common/constants";
import { Paths } from "../../routes/paths";

const table_columns = [
    {
      title: 'ID',
      dataIndex: 'id'
    },
    {
      title: 'Title',
      dataIndex: 'book_id',
      sorter: (a, b) => a.book_id > b.book_id
    },
    {
      title: 'Message',
      dataIndex: 'ledger',
      sorter: (a, b) => a.ledger > b.ledger
    },
    {
      title: 'Type',
      dataIndex: 'type',
      sorter: (a, b) => a.type > b.type,
      render: (type) => type.toUpperCase()
    },
    {
      title: '',
      dataIndex: 'marked_pan',
      render: _ => <Link to="#" style={{display: 'flex', justifyContent: 'flex-end'}}><Icons.CounterMenu color={Themes.gray_100} /></Link>
    }
  ];
  
const table_data = [
  
];

for (var i = 0; i < 100; i++) {
  let status = (i%2 === 0? 'Success' : (i%3 === 0? 'Pending' : 'Canceled'));

  table_data.push({
    id: i + 1, 
    book_id: 1235 + i, 
    ledger: 'raymond' + i, 
    type: 'Ledger',
    date: (Math.floor(i/5) + 1 + Math.floor(Math.random() * 10)) + ' Mar 19:30 WAT', 
    status: <span className={`status-before-content shape-circle ${status.toLowerCase()}`}>{status}</span>,
  });
}


const tab_data = [
  {title: 'All Transactions', key: Paths.transaction_history}, 
  {title: 'Completed', key: Paths.transaction_history + '/completed'}, 
  {title: 'Pending', key: Paths.transaction_history + '/pending'}, 
  {title: 'Canceled', key: Paths.transaction_history + '/canceled'}
];

const accountingData = {
    table: {columns: table_columns, data: table_data},
    tab: tab_data
};

export default accountingData;



