import { Form } from "antd";
import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { PublicLayout } from "../../common/components";
import {
  Input,
  Heading,
  VSpace,
  FormGroup,
  Button,
  Block
} from "../../common/components/styles";
import { Paths } from "../../routes/paths";




const ResetPassword = () => {
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate(Paths.password_reset_success);
  };

  return (
    <PublicLayout>
      <Heading className="text-center" size="20px" weight={500}>
        Reset your Password
      </Heading>
      <VSpace space="10px" />

      <Heading className="text-center" size="14px">
        Enter your new password
      </Heading>
      <VSpace space="24px" />

      <Form onFinish={handleSubmit}>
        <FormGroup direction="column" margin="1rem 0rem">
          <Input
            labelProps={{
              text: "Password",
              htmlFor: "password"
            }}
            inputProps={{
              type: "password",
              name: "password",
              id: "password",
              placeholder: ""
            }}
          />
        </FormGroup>

        <FormGroup direction="column" margin="1rem 0rem">
          <Input
            labelProps={{
              text: "Confirm Password",
              htmlFor: "confirm_password"
            }}
            inputProps={{
              type: "password",
              name: "confirm_password",
              id: "confirm_password",
              placeholder: ""
            }}
          />
        </FormGroup>

        <Block variant="p" className="paragraph-1 size-12 text-gray-1">
          Password must contain a minimum of 8 characters, with an uppercase
          letter, a lowercase letter, a number and a special character
        </Block>

        <FormGroup className="mt-3">
          <Button fullWidth secondary cursor="true" type="submit">
            Change Password
          </Button>
        </FormGroup>
      </Form>
    </PublicLayout>
  );
};

const structuredSelector = createStructuredSelector({
  auth: state => state.auth
});



const mapDispatchToProps = {};

export default connect(structuredSelector, mapDispatchToProps)(ResetPassword);
