
import React from 'react';
import { Outlet } from 'react-router-dom';

const Payment = () => {
    return(
        <Outlet />
    );
}

export default Payment;