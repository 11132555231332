import { all, call, put, takeLatest } from 'redux-saga/effects';
import dashboardApi from './dashboard.api';
import { dashboard } from '../../common/constants';

import { errorAsyncSaga } from '../common/sagas';
import { commonActions } from '../common/actions';


function* asyncFetchData(action) {
  yield put(commonActions.loading(dashboard.fetchData));

  try {
    const response = yield call(dashboardApi.fetchData, action.payload);
    yield put(commonActions.success(dashboard.fetchData,  response.data));
  }
  catch(err) {
    const errorData = err?.response?.data?? {message: 'An unknown error has occurred!'};
    yield call(errorAsyncSaga, {actionName: dashboard.fetchData, error: errorData})
  }
}

function* watchFetchDataSaga() {
  yield takeLatest(dashboard.fetchData, asyncFetchData);
}

function* asyncFetchGraphData(action) {
  yield put(commonActions.loading(dashboard.fetchGraphData));
  try {
    const response = yield call(dashboardApi.fetchGraphData, action.payload?.auth, action.payload?.data);
    yield put(commonActions.success(dashboard.fetchGraphData,  response.data));
  }
  catch(err) {
    const errorData = err?.response?.data?? {message: 'An unknown error has occurred!'};
    yield call(errorAsyncSaga, {actionName: dashboard.fetchGraphData, error: errorData});
  }
}

function* watchFetchGraphDataSaga() {
  yield takeLatest(dashboard.fetchGraphData, asyncFetchGraphData);
}



export default function* DashboardSagas() {
  yield all([
    watchFetchDataSaga(),
    watchFetchGraphDataSaga()
  ])
}

