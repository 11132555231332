import React from "react";
import { useNavigate } from "react-router-dom";
import { PublicLayout } from "../../common/components";

import { Anchor, Block } from "../../common/components/styles";

const NoMatch = () => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    navigate(-1);
    e.preventDefault();
  }
  return (
    <PublicLayout>
      <Block variant="h1">
        The route you are looking for does not exist.{" "}
        <Anchor href="#" onClick={handleClick}>Back to site</Anchor>
      </Block>
    </PublicLayout>
  );
};

export default NoMatch;
