
import { connect } from '../../api/connect';

const serviceApi = {
    fetchServices: async (authState, pageNumber) => {
        const params = pageNumber? `page=${pageNumber}` : '';
        const response = await connect(authState).get(`admin/services?${params}`);
        return response;
    },
    fetchServiceDetail: async (authState, data) => {
        const { accountId } = data;
        const response = await connect(authState).get(`admin/services/${accountId}`);
        return response;
    },
    blockService: async (authState, data) => {
        const { account_id } = data;
        const response = await connect(authState).get(`admin/services/${account_id}/block`);
        return response;
    },
    addNewService: async (authState, data) => {
        const response = await connect(authState).post(`admin/services/new`, data);
        return response;
    },
}
  
export default serviceApi;