import { Input } from 'antd';
import React from 'react';
import * as PropTypes from 'prop-types';

import { Icons } from '../constants';


const SearchInput = ({placeholder, prefixIcon, onChange, ...rest}) => {
    return (
        <Input  
            onChange={onChange}
            placeholder={placeholder}
            prefix={prefixIcon?? <Icons.Search className="stroke" />} 
            {...rest}   
        />
    )
}

SearchInput.propTypes = {
    placeholder: PropTypes.string
}

export default SearchInput;