import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage';

import rootSaga from './root.saga';
import rootReducer from './root.reducer';

// we need an initialState otherwise , store will freak out
const initialState = {
  auth: {}
}

const persistConfig = {
  key: 'root',
  storage,
}


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware()

// redux sagas is a middleware that we apply to the store
const store = createStore(
  persistedReducer,
  initialState,
  composeEnhancers(applyMiddleware(sagaMiddleware))
)

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga)

export {
  store,
  persistor
}