import { Table, Grid } from 'antd';
import React, { useState } from 'react';
import * as PropTypes from 'prop-types';

const { useBreakpoint } = Grid;

const CustomTable = ({useCheckedSelection = false, className, ...rest}) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const screens = useBreakpoint();

    const onSelectChange = selectedRowKeys => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange
    };

    return (
        <Table 
            scroll={{ x: (!screens.md || !screens.lg)? 1250 : 'auto' }}
            className={className? 'table ' + className : 'table'}
            {...rest} 
            rowSelection={useCheckedSelection? rowSelection : null} 
        />
    );
}

CustomTable.propTypes = {
    useCheckedSelection: PropTypes.bool,
    className: PropTypes.string
}

export default CustomTable;