import { put } from 'redux-saga/effects';

import { Auth } from "../../common/constants";
import { commonActions } from "./actions";
import { refreshTokenAction } from '../auth';


export function* errorAsyncSaga(action) {
    const status = action?.error?.status?.toLowerCase();
  
    if (action?.actionName !== Auth.loginRequest && status?.includes('expired')) {
      yield put(refreshTokenAction());
    }
    
    yield put(commonActions.error(action?.actionName, {error: action?.error}, action?.currentPage));
}