import React from "react";
import { Avatar, Layout, Menu } from "antd";
import { Icons, Themes } from "../constants";
import { AgSidebarCloser } from ".";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { logoutRequestAction } from "../../state/auth";
import { Paths } from "../../routes/paths";


const Header = ({
  pageTitle,
  pageIcon,
  logout,
  auth,
  sidebar_collapsed_class = "",
  ...rest
}) => {

  //const {user} = auth?? {};

  const handleMenuClicked = (e) => {
    switch(e.key) {
        case Paths.logout:
            logout(auth);
            break;
        default:
            break;
    }
  }

  return (
    <Layout.Header className="header header-light"  {...rest}>
      <AgSidebarCloser
          pageTitle={pageTitle}
          pageIcon={pageIcon}
          style={{
            marginLeft: "34px"
          }}
      />
      <Menu
        className={`site-layout-background site-menu flex-right ${sidebar_collapsed_class}`}
        mode="horizontal"
        overflowedIndicator={<Icons.Menu size={26} />}
        triggerSubMenuAction='click'
        onClick={handleMenuClicked}
      >
        <Menu.Item key="1">
          <Icons.Notification size={20} color={Themes.secondary} />
        </Menu.Item>
        <Menu.SubMenu
            key="3"
            title={
              <Avatar size={40} src="https://joeschmoe.io/api/v1/random" />
            }
          >
            <Menu.Item key={Paths.logout}>Logout</Menu.Item>
          </Menu.SubMenu>
      </Menu>
    </Layout.Header>
  );
};

const structuredSelector = createStructuredSelector({
    auth: state => state.auth,
})
  
const mapDispatchToProps = dispatch => {  
    return {
        logout: (state) => dispatch(logoutRequestAction(state))
    }
}

export default connect(structuredSelector, mapDispatchToProps)(Header);
  
