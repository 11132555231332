
import { connect } from '../../api/connect';

const AuthApi = {
    loginUser: async (credentials) => {
        const response = await connect().post('auth/login', credentials);
        return response;
    },
    logoutUser: async (payload) => {
        const response = await connect(payload).post('auth/logout');
        return response;
    }
}
  
export default AuthApi;