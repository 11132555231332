
import { connect } from '../../api/connect';

const customerApi = {
    fetchCustomers: async (authState, pageNumber) => {
        const params = pageNumber? `page=${pageNumber}` : '';
        const response = await connect(authState).get(`admin/customers?${params}`);
        return response;
    },
    fetchCustomerDetail: async (authState, data) => {
        const { accountId } = data;
        const response = await connect(authState).get(`admin/customers/${accountId}`);
        return response;
    },
    blockCustomer: async (authState, data) => {
        const { account_id } = data;
        const response = await connect(authState).get(`admin/customers/${account_id}/block`);
        return response;
    },
    addNewCustomer: async (authState, data) => {
        const response = await connect(authState).post(`admin/customers/new`, data);
        return response;
    },
}
  
export default customerApi;