import React from 'react';
import { Menu } from 'antd';

import { menu as _m } from '../../routes/menu';

const SidebarMenu = (props) => {
    return(
        <Menu
          {...props}
        >
            {
                _m.sidebar_menu_list.map( (item, _) =>
                    (
                        item?.submenu? (
                            <Menu.SubMenu title={item.title} icon={item.icon} key={item.path}>
                                {
                                    item.submenu.map( (subItem, _)  => (
                                        <Menu.Item key={subItem.path}>
                                            {subItem.title}
                                        </Menu.Item>
                                    ))
                                }
                            </Menu.SubMenu>
                        ) 
                        : 
                        (
                            <Menu.Item key={item.path} icon={item.icon}>
                            {item.title}
                            </Menu.Item>
                        )
                    )
                )
            }
        </Menu>
    );
}

export default SidebarMenu;