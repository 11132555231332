const Admin = {
    fetchAdmins: 'FETCH_ADMIN_DATA_REQUEST',
    addNewAdmin: 'ADD_NEW_ADMIN',
    cancelAdmin: 'CANCEL_ADMIN_REQUEST',
    downloadReport: 'DOWNLOAD_ADMIN_REPORT_REQUEST',
    cancelAdminAsync: 'CANCEL_ADMIN_REQUEST_ASYNC',
    downloadReportAsync: 'DOWNLOAD_ADMIN_REPORT_REQUEST_ASYNC',
    printReport: 'PRINT_ADMIN_REPORT',
    printReportAsync: 'PRINT_ADMIN_REPORT_ASYNC',
    success: 'ADMIN_SUCCESS',
    error: 'ADMIN_ERROR',
    loading: 'ADMIN_LOADING'
};

export default Admin;