import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Themes } from '../constants';

const Spinner = ({loading, size=18, color = Themes.dark, ...rest}) => {
    if (!loading)
        return <></>

    return <LoadingOutlined style={{fontSize: size + 'px', color}} {...rest}  spin />;
}

export default Spinner;