const getFullYear = () => {
  return new Date().getFullYear();
}

const translateError = (error) => {
  if (error) {
    const e = (error + '').toLowerCase();
    if (e.includes('network')) {
      return {message: 'Please check your network connection.'};
    }
    else if (e.includes('timeout')) {
      return {message: 'Connection timeout'};
    }
    else {
      return {message: 'Unknown error'};
    }
  }
}
const capitalize = (string) => {
  if (string)
    return string?.charAt(0)?.toUpperCase() + string.slice(1);
  
  return string;
}

const queryString = (string) => {
  if (string?.length > 0) {
    const temp = string.split('&')?.map(item => {
      const part = item.replace('?','').split('=')?.map(part => {
        return part;
      });
      if (part?.length === 2) {
        return {
          [part[0]] : part[1]
        }
      }
      return null;
    });

    let output = {};

    for (let i = 0; i < temp?.length; i++) {
      output = {...output, ...temp[i]}
    }
    return output;
  }

  return null;
}

const numFormat = (num, digits = 1) => {
  if (!isNaN(num)) return num;

  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function(item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}

const numWithCommas = (x) => {
  if (x)
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return x;
}

const hexToRgba = (hex, opacity=1) => {
  var c;
  if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
      c= hex.substring(1).split('');
      if(c.length === 3){
          c= [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c= '0x'+c.join('');
      return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',' + opacity + ')';
  }

  return null;
}

const filterData = (searchText, data, filterLogicCallback) => {
  const filteredData = data?.filter(
    entry => filterLogicCallback(searchText, entry)
  );
  
  return searchText.length === 0? data : filteredData
}

const titleCase = (str) => {
  return str.replace(/(^|\s)\S/g, function(t) { return t.toUpperCase() });
}

const formatAmount = (amount, currency = '', useFormat = true, placement = 'left') => {
  if (!isNaN(amount)) {
    let amt = useFormat? numFormat(amount, 2) : amount;
    amt = numWithCommas(amount);
    return placement?.toLowerCase() === 'left'? `${currency}${amt}` : `${amt}${currency}`
  }
  return amount;
}



const Util = {
   filterData, titleCase, hexToRgba, numWithCommas, numFormat, capitalize, getFullYear,
   formatAmount, translateError, queryString
}

export default Util;
