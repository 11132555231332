import { all, call, put, takeLatest } from 'redux-saga/effects';
import TransactionApi from './transaction.api';
import { transaction } from '../../common/constants';
import { commonActions } from '../common/actions';
import { errorAsyncSaga } from '../common/sagas';


function* asyncFetchTransactionData(action) {
  yield put(commonActions.loading(transaction.fetchData));
  try {
    const response = yield call(TransactionApi.fetchTransactionData, action.payload.auth, action.payload.pageNumber);
    yield put(commonActions.success(transaction.fetchData, response.data));
  }
  catch(err) {
    const errorData = err?.response?.data?? {message: 'An unknown error has occurred!'};
    yield call(errorAsyncSaga, {actionName: transaction.fetchData, error: errorData, currentPage: action.payload.pageNumber});
  }
}

export function* watchFetchDataSaga() {
  yield takeLatest(transaction.fetchData, asyncFetchTransactionData);
}

function* asyncFetchTransactionDetail(action) {
  yield put(commonActions.loading(transaction.fetchTransactionDetail));
  try {
    const response = yield call(TransactionApi.fetchTransactionDetail, action.payload.auth, action.payload.txRef);
    yield put(commonActions.success(transaction.fetchTransactionDetail, response.data));
  }
  catch(err) {
    const errorData = err?.response?.data?? {message: 'An unknown error has occurred!'};
    yield call(errorAsyncSaga, {actionName: transaction.fetchTransactionDetail, error: errorData});
  }
}

export function* watchFetchTransactionDetailSaga() {
  yield takeLatest(transaction.fetchTransactionDetail, asyncFetchTransactionDetail);
}

function* asyncCancelTransaction(action) {
  yield put(commonActions.loading(transaction.cancelTransaction));
  try {
    const response = yield call(TransactionApi.cancelTransaction, action?.auth, action?.payload?.id);
    yield put(commonActions.success(transaction.cancelTransaction, response.data));
  }
  catch(err) {
    const errorData = err?.response?.data?? {message: 'An unknown error has occurred!'};
    yield call(errorAsyncSaga, {actionName: transaction.cancelTransaction, error: errorData});
  }
}

export function* watchCancelTransactionSaga() {
  yield takeLatest(transaction.cancelTransactionAsync, asyncCancelTransaction);
}

function* asyncDownloadReport(action) {
  yield put(commonActions.loading(transaction.downloadReport));
  try {
    const response = yield call(TransactionApi.downloadReport, action?.auth, action?.payload?.id);
    yield put(commonActions.success(transaction.downloadReport, response.data));
  }
  catch(err) {
    const errorData = err?.response?.data?? {message: 'An unknown error has occurred!'};
    yield call(errorAsyncSaga, {actionName: transaction.downloadReport, error: errorData});
  }
}

export function* watchDownloadReportSaga() {
  yield takeLatest(transaction.downloadReportAsync, asyncDownloadReport);
}

function* asyncPrintReport(action) {
  yield put(commonActions.loading(transaction.printReport));
  try {
    const response = yield call(TransactionApi.printReport, action?.auth, action?.payload);
    yield put(commonActions.success(transaction.printReport, response.data));
  }
  catch(err) {
    const errorData = err?.response?.data?? {message: 'An unknown error has occurred!'};
    yield call(errorAsyncSaga, {actionName: transaction.printReport, error: errorData})
  }
}

export function* watchPrintReportSaga() {
  yield takeLatest(transaction.printReportAsync, asyncPrintReport);
}



export default function* transactionSagas() {
  yield all([
    watchFetchDataSaga(),
    watchFetchTransactionDetailSaga(),
    watchCancelTransactionSaga(),
    watchDownloadReportSaga(),
    watchPrintReportSaga()
  ])
}

