import { dashboard } from '../../common/constants'



const fetchData = (payload) => {
  return {
    type: dashboard.fetchData,
    payload: payload
  }
}

const fetchGraphData = (authState, data) => {
  return {
    type: dashboard.fetchGraphData,
    payload: {
      auth: authState,
      data
    }
  }
}




const Actions = {
  fetchData,
  fetchGraphData
}

export default Actions;


