import React, { useLayoutEffect } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { useLocation } from 'react-router-dom';
import { CustomerInfoCard, TransactionInfoCard } from '../../common/components';

const TransactionDetail = () => {
    const { state } = useLocation();

    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [state?.transactionId]);
    
    return(
        <Grid>
               <Row>
                   <Col md={11}>
                        <TransactionInfoCard txRef={state?.transactionId} />  
                        <CustomerInfoCard accountId={state?.accountId} />
                   </Col>
               </Row>
           </Grid>
    );
}

export default TransactionDetail;