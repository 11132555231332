import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { authApi } from "../../state/auth";
import { Outlet } from "react-router-dom";
import { Block } from "../../common/components/styles";
import { Col, Row } from "react-flexbox-grid";
import { Themes } from "../../common/constants";

const UserManagement = () => {
  return (
    <Block
      variant="div"
      background={Themes.white}
      padding="24px"
      borderRadius="8px"
    >
      <Row className="mt-2">
        <Col xs={12}>
          <Outlet />
        </Col>
      </Row>
    </Block>
  );
};

const structuredSelector = createStructuredSelector({
  data: (state) => state.user
});

const mapDispatchToProps = { loginUser: authApi.loginUser };

export default connect(structuredSelector, mapDispatchToProps)(UserManagement);
