import React, { useEffect } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import InfoContainer from './info-container';
import Info from './info';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { paymentActions, paymentConstants } from "../../../state/payment";
import { Util } from '../../../util';
import ButtonInput from '../button';
import { Icons, Themes } from '../../constants';
import Spinner from '../spinner';


const { fetchPaymentDetail } = paymentActions;


const PaymentInfo = ({auth, txnDataFetched, fetchPaymentDetail, txRef}) => {
    const { loading, data: txnData } = txnDataFetched;

    useEffect(() => {
        if (!auth.loading && !auth.refresh_token)
            fetchPaymentDetail(auth, {txRef});
    }, [fetchPaymentDetail, auth, txRef]);

    const { 
        reference, external_reference, user, pre_balance, post_balance, created_at,
        currency, provider, charge, amount, status, payment_method
    } = txnData?? {};

    const StatusElement = () => {
        switch(status?.toLowerCase()) {
            case 'success':
                return <Icons.Success size={16} color={Themes.success} />;
            case 'failed':
                return <Icons.Failed size={16} color={Themes.danger} />
            default:
                return <Icons.Pending size={16} color={Themes.warning} />
        }
    }

    return(
        <Grid className='mt-1'>
            <InfoContainer loading={loading} className='mb-2'
                header={
                    <Row className='mb-1'>
                        <Col md={6} className='flex-middle'>
                            {loading? <Spinner loading={loading} /> : <>
                                <Row>
                                    <Col md={6}>
                                        <h2 className='h1'>{Util.formatAmount(amount?? 0, currency?? 'NGN')} <StatusElement /></h2>
                                        <h4 className='h4'>Payment from <span className='wt-600'>{user?.user_data?.name?? user?.email}</span>
                                        </h4>
                                    </Col>
                                    <Col md={6}>
                                        <h2 className='h2 mt-1'>
                                            Payment Date: <br /><br /><span className='wt-600'>{created_at}</span>
                                        </h2>
                                    </Col>
                                </Row>
                            </>}
                        </Col>
                        <Col md={6} className='flex flex-middle flex-right py-1'>
                            <ButtonInput success icon={<Icons.Accounting />}>Send Invoice</ButtonInput>
                        </Col>
                    </Row>   
                }
            >
                <Info md={3} title={'Payment Processor'} description={provider?.name?? 'N/A'} />
                <Info md={3} title={'Payment Method'} description={payment_method?? 'N/A'} />
                <Info xs={4} md={3} title={'Payment Invoice'} description={reference} />
                <Info md={3} title={'External Reference'} description={external_reference?? 'N/A'} />
                <Info md={3} title={'Amount'} description={Util.formatAmount(amount?? 0, currency?? '')} />
                <Info md={3} title={'Payment Fee'} description={Util.formatAmount(charge?? 0, currency, false)} />
                <Info md={3} title={'Pre. Balance'} description={Util.formatAmount(pre_balance?? 0, currency, false)} />
                <Info md={3} title={'Cur. Balance'} description={Util.formatAmount(post_balance?? 0, currency, false)} />
            </InfoContainer>
        </Grid>
    );
}

const structuredSelector = createStructuredSelector({
    auth: (state) => state.auth,
    txnDataFetched: (state) => state?.app[paymentConstants.fetchPaymentDetail]?? {},
});
  
const mapDispatchToProps = (dispatch) => {
    return {
      fetchPaymentDetail: (state, data) => dispatch(fetchPaymentDetail(state, data))
    };
};
  
export default connect(structuredSelector, mapDispatchToProps)(PaymentInfo);