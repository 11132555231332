import styled, { css, createGlobalStyle } from 'styled-components';
import reset from 'react-style-reset/string';
import { Themes, Images } from '../constants';
import React from 'react';
import { Util } from '../../util';

export const GlobalStyles = createGlobalStyle`
    ${reset};

    html, body, #root {
        height: 100%;
        padding: 0px;
        margin: 0px;
    } 

    body {
        font-size: 14px;
        font-family: 'Rubik', sans-serif !important;
    }
    
    * {
        box-sizing: border-box;
        outline: none;
    };
    .wt-normal {
        font-weight: 400 !important;
    }
    .wt-500 {
        font-weight: 500 !important;
    }
    .wt-600 {
        font-weight: 600 !important;
    }
    .text-center {
        text-align: center !important;
    }
    .text-left {
        text-align: left !important;
    }
    .text-right {
        text-align: right !important;
    }
    .inline-flex {
        display: inline-flex !important;
    }
    .flex-middle {
        align-items: center !important;
    }
    .flex-left {
        justify-content: flex-start !important;
    }
    .flex-right {
        justify-content: flex-end !important;
    }
    .br-12 {
        border-bottom-left-radius: 12px !important;
        border-bottom-right-radius: 12px !important;
    }
    .p-1d2 {
        padding: 0.5rem !important;
    }
    .p-1 {
        padding: 1rem !important;
    }
    .p-2 {
        padding: 2rem !important;
    }

    .p-3 {
        padding: 3rem !important;
    }

    .p-4 {
        padding: 4rem !important;
    }

    .mt-1 {
        margin-top: 1rem !important;
    }
    .mt-2 {
        margin-top: 2rem !important;
    }
    .mt-3 {
        margin-top: 3rem !important;
    }

    .mt-4 {
        margin-top: 4rem !important;
    }

    .mb-1 {
        margin-bottom: 1rem !important;
    }
    .mb-2 {
        margin-bottom: 2rem !important;
    }
    .mb-3 {
        margin-bottom: 3rem !important;
    }

    .mb-4 {
        margin-bottom: 4rem !important;
    }

    .mt-half {
        margin-title: 0.5rem !important;
    }

    .mb-half {
        margin-bottom: 0.5rem !important;
    }

    .d-flex {
        display: flex !important;
    }

    .bd {
        border: 1px solid #eee;
    }

    .bd-1 {
        border: 2px solid #eee;
    }

    .bd-2 {
        border: 4px solid #eee;
    }

    .py-1 {
        padding: 0.5rem 0rem;
    }

    .py-2 {
        padding: 1rem 0rem;
    }

    .px-1 {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
    }

    .px-2 {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    .p-2 {
        padding: 1rem;
    }


    .success-icon-container {
        width: 150px;
        height: 150px;
        background: ${Themes.success_light};
        color: ${Themes.success_dark};
        border-radius: 50%;
    }

    .paragraph-1 {
        line-height: 21.12px;
        font-weight: 400;
        font-size: 14px;
    }

    .size-10 {
        font-size: 10px !important;
    }

    .size-12 {
        font-size: 12px !important;
    }

    .size-14 {
        font-size: 14px !important;
    }

    .text-gray-1 {
        color: ${Themes.gray_100} !important;
    }

    .text-white-50 {
        color: ${Themes.white_50}
    }
`;


const LogoComponent = ({src, alt, ...rest}) => {
    return (
        <img alt={alt?? 'Logo'} src={src?? Images.logo } {...rest} />
    );
}

export const Logo = styled(LogoComponent)`
    
`;


export const Button = styled.button`
    background: transparent;
    border-radius: 8px;
    border: 2px solid ${Themes.transparent};
    cursor: ${props => props?.cursor? 'pointer' : 'auto'};
    color: ${Themes.white};
    padding: 14px;
    ${props => props.fullWidth && 
        css`
            width: 100%;
        `
    };

    ${props =>
        props.primary &&
            css`
            background: ${Themes.secondary};
            border: 2px solid ${Themes.secondary};
            color: ${Themes.white};
            transition: all 0.4s;
            &:hover {
                background: ${Themes.secondary_light};
                border-color: ${Themes.secondary_light};
            }
        `
    };

    ${props =>
        props.secondary &&
            css`
            background: ${Themes.secondary};
            border: 2px solid ${Themes.secondary};
            color: ${Themes.white};
            transition: all 0.4s;
            &:hover {
                background: ${Themes.secondary_light};
                border-color: ${Themes.secondary_light};
            }
        `
    };
`;

export const VSpace = styled.div`
    height: ${props => props.space?? '20px'}
`;

export const Body1 = styled.div`
    vertical-align: top;
    display: flex;
    background: ${props => props.background? props.background : `${Themes.secondary} url(${Images.body1}) no-repeat bottom left`};
    background-size: cover;
    text-align: center;
    min-height: 100%;
`;

export const StyledBox = styled.div`
    background-color: ${props => props.backgroundColor?? Themes.transparent};
    width: ${props => props.width?? 'auto'};
    height: ${props => props.height?? 'auto'};
    min-width: ${props => props.minWidth?? 'auto'};
    margin: ${props => props.margin?? '0rem'};
    padding: ${props => props.padding?? '0rem'};
    border-radius: ${props => props.radius?? '12px'};
    text-align: ${props => props.textAlign?? 'left'};
`;

const LabelComponent = ({text, ...rest}) => {
    return <label {...rest}>{text}</label> 
}
export const Label = styled(LabelComponent)`
    margin-bottom: 8px;
    display: flex;
    color: ${props => props.color?? '#9B9B9B'}
`;


const InputComponent = (props) => {
    const {inputProps, labelProps, ...rest} = props;
    return (
        <>
            {labelProps && <Label {...labelProps} />}
            <input {...inputProps} {...rest} />
        </>
    );
}

export const Input = styled(InputComponent)`
    width: 100%;
    display: ${props => props.display?? 'block'};
    background: ${props => props.background?? Util.hexToRgba(Themes.secondary, 0.05)};
    padding: ${props => props.padding?? '14px'};
    border: ${props => props.border?? '0px'};
    border-radius: 8px;
    transition: all 0.4s;

    &:focus {
        outline: none;
        background-color: ${Util.hexToRgba(Themes.black, 0.1)};
    }
`;


export const HeadingComponent = ({type: Component = 'h1', children, ...rest}) => {
    return (
        <Component {...rest}>{children}</Component>
    );
}

export const Heading = styled(HeadingComponent)`
        color: ${props => props.color?? 'rgba(0, 0, 0, 0.88)'};
        line-height: ${props => props.lineSpacing?? '132%'};
        font-weight: ${props => props.weight?? '500'};
        font-size: ${props => props.size?? '16px'};
        letter-spacing: 0.1px;
`;


export const FormGroup = styled.div`
    display: flex;
    flex-direction: ${props => props.direction?? 'row'};
    justify-content: space-between;
    margin: ${props => props.margin?? '0px'};
    padding: ${props => props.padding?? '0px'};
`;

export const BlockComponent = React.forwardRef((props, ref) => { 
    const {variant: Component, children, borderRadius, ...rest} = props;
    
    return (
        <>
            {children? <Component ref={ref} {...rest}>{children}</Component> : <Component {...rest} />}
        </>
    );
});

export const Block = styled(BlockComponent)`
        display: ${props => props.display?? 'block'};
        flex-direction: ${props => props.direction?? 'row'};
        justify-content: ${props => props.justifyContent?? 'normal'};
        align-items: ${props => props.alignItems?? 'center'};
        margin: ${props => props.margin?? '0px'};
        padding: ${props => props.padding?? '0px'};
        background: ${props => props.background?? Themes.transparent};
        min-width: ${props => props.minWidth?? 'auto'};
        max-width: ${props => props.maxWidth?? 'auto'};
        flex: ${props => props.flex?? '0 1 auto'};
        border-radius: ${props=>props.borderRadius?? '0px'};
        width: ${props=>props.width?? 'auto'};
        height: ${props=>props.height?? 'auto'};
        flex-wrap: ${props=>props.wrap?? 'nowrap'};
        column-gap: ${props=>props.gapX?? 'normal'};
        row-gap: ${props=>props.gapY?? 'normal'}
`;


const AnchorStyled = styled.a`
    text-decoration: none;
    color: ${props => props.color?? Themes.secondary};
    display: ${props => props.display?? 'inline-block'};
    align-self: center;
    color: ${props => props.color?? Themes.gray};
`;

export const Anchor = ({children, ...rest}) => {
    return <AnchorStyled {...rest}>{children}</AnchorStyled>
}


