import { Button, Form, Input, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { Heading, VSpace } from "./styles";

import {
  adminActions,
  adminConstants
} from "../../state/admin";


const { addNewAdmin } = adminActions;



const roles = [
  {
    name: 'Accountant',
  },
  {
    name: 'Compliance Officer'
  },
  {
    name: 'Customer Support'
  },
  {
    name: 'Super Admin'
  }
];

const AddNewAdmin = ({ admin_added, addNewAdmin, auth, visible, onCancel }) => {
  const [newAdminAdded, startNewAdminAdding] = useState(false);

  const onFinish = (values) => {
    const names = values?.name?.split(' ');
    
    const data = {...values, first_name: names[0]?? '', last_name: names[1]?? '', country: values?.country?? 'NG'};

    addNewAdmin(auth, data);

    setTimeout(() => {
      startNewAdminAdding(true);
    }, 3000);
  };

  const { error, loading, status, message } = admin_added;

  useEffect(() => {
    if (!loading && newAdminAdded && status?.toLowerCase() === 'success') {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }, [loading, status, message, newAdminAdded]);

  return (
    <Modal title="New Admin" visible={visible} footer={null} closable={true} onCancel={onCancel}>
      <Form
        layout="vertical"
        name="admin_added"
        initialValues={{
          remember: true
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        {status === "error" && !loading && <Heading className='text-danger'>{error?.message}</Heading>}
        {status === "success" && newAdminAdded && <Heading className='text-success'>{message}. Updating admin history in 2secs...</Heading>}
        <VSpace />
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Name is required"
            }
          ]}
        >
          <Input />
        </Form.Item>
        <VSpace space="2px" />
        <Form.Item
          label="Email Address"
          name="email"
          rules={[
            {
              required: true,
              message: "Email address is required"
            }
          ]}
        >
          <Input />
        </Form.Item>
        <VSpace space="2px" />
        <Form.Item
          label="Mobile Number"
          name="phone_number"
          rules={[
            {
              required: true,
              message: 'Mobile Number is required'
            }
          ]}
        >
          <Input />
        </Form.Item>

        <VSpace space="2px" />
        <Form.Item
          label="Role"
          name="role"
          rules={[
            {
              required: true,
              message: 'Please select a role'
            }
          ]}
        >
          <Select
            defaultValue=""
            placeholder="Select Role"
          >
            {
              roles?.map((role, index) => (
                <Select.Option key={index} value={role?.name}>{role?.name}</Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <VSpace />
        <Form.Item
        >
          <Button loading={loading} type="primary" htmlType="submit">
            Invite
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};


const structuredSelector = createStructuredSelector({
  auth: state => state.auth,
  admin_added: state => state?.app[adminConstants.addNewAdmin] ?? {}
});

const mapDispatchToProps = dispatch => {
  return {
    addNewAdmin: (state, payload) => dispatch(addNewAdmin(state, payload))
  };
};

export default connect(structuredSelector, mapDispatchToProps)(AddNewAdmin);