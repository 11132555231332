
import { connect } from '../../api/connect';

const adminApi = {
    fetchAdmins: async (authState, pageNumber) => {
        const params = pageNumber? `page=${pageNumber}` : '';
        const response = await connect(authState).get(`admin/admins?${params}`);
        return response;
    },
    fetchAdminDetail: async (authState, data) => {
        const { accountId } = data;
        const response = await connect(authState).get(`admin/admins/${accountId}`);
        return response;
    },
    blockAdmin: async (authState, data) => {
        const { account_id } = data;
        const response = await connect(authState).get(`admin/admins/${account_id}/block`);
        return response;
    },
    addNewAdmin: async (authState, data) => {
        const response = await connect(authState).post(`admin/admins/new`, data);
        return response;
    },
}
  
export default adminApi;