import { all, call, put, takeLatest } from 'redux-saga/effects';
import authApi from './auth.api';
import { Auth } from '../../common/constants';
import { Util } from '../../util';
import { loginError, loginSuccess, logoutSuccess, logoutError } from './auth.actions';


function* asyncLoginUser(action) {
  try {
    const response = yield call(authApi.loginUser, action.payload);
    yield put(loginSuccess(response.data));
  }
  catch(error) {
    let errorData = error?.response?.data?? null;

    if (!errorData)
      errorData = Util.translateError(error);
    
    yield put(loginError(errorData));
  }
}

function* asyncLogoutUser(action) {
  try {
    yield call(authApi.logoutUser, action.payload);
  }
  catch(error) {
    yield put(logoutError(error));
  }
  yield put(logoutSuccess());
}



export function* watchLoginRequestSaga(action) {
  yield takeLatest(Auth.loginRequest, asyncLoginUser);
}
export function* watchLogoutRequestSaga() {
  yield takeLatest(Auth.logoutRequest, asyncLogoutUser);
}


export default function* authSagas() {
  yield all([
    watchLoginRequestSaga(),
    watchLogoutRequestSaga()
  ])
}

