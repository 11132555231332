import React from "react";
import { Col, Row } from "react-flexbox-grid";
import { Themes } from "../../constants";
import Spinner from "../spinner";

const InfoContainer = ({
  children,
  className,
  title,
  titleInBox = false,
  titleClassName,
  loaderColor=Themes.secondary,
  loaderSize=16,
  loading=false,
  loaderPosition='right',
  header,
  headerWrapInContainer=false
}) => {
  const mergedClassNames = `info__container ${className?? ''}`;
  const titleClassNames = `mb-half ${titleClassName?? ''}`;

  const titleElement = title && <h1 className={titleClassNames}>{title}</h1>;

  return (
    <>
      {!headerWrapInContainer && header}
      {!titleInBox && titleElement}
      <Row className={mergedClassNames} style={{ position: "relative" }}>
        <Col md={12}>
          <Row>
            <Col md={12}>
              {<Loader position={loaderPosition} iconColor={loaderColor} iconSize={loaderSize} loading={loading} />}
              {headerWrapInContainer && header}
              {titleInBox && titleElement}
            </Col>
          </Row>
          <Row>
            {children}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default InfoContainer;

const Loader = ({iconColor, iconSize, loading, position}) => {
    let placement = 'left';
    switch(position?.toLowerCase()) {
        case 'left':
            placement = '97%';
            break;
        case 'right':
            placement = '2%';
            break;
        default:
            placement = '48%'
    }

  return (
    <div
      style={{
        position: "absolute",
        right: placement,
        top: "12px"
      }}
    >
      <Spinner color={iconColor} loading={loading} size={iconSize} />
    </div>
  );
};
