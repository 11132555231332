
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { AddAdminModal, AgButton, AgTable, NavTab, TableStatSearch } from '../../common/components';
import { Block } from '../../common/components/styles';
import { Icons, Themes } from '../../common/constants';
import { adminActions, adminConstants } from '../../state/admin';
import { Util } from '../../util';
import adminData from './admin-data';



const AdminListing = ({auth, fetchAdmins, adminsFetched}) => {
    const navigate = useNavigate();
    const {search, pathname}= useLocation();
    const [dataSource, setDataSource] = useState([]);
    const [inviteAdminVisible, showInviteAdmin] = useState(false);

    const {
        data: admins,
        loading: adminsLoading
    } = adminsFetched?? {};
    
    useEffect(() => {
      if (!auth.loading && !auth.refresh_token) {
        const page = Util.queryString(search);
        fetchAdmins(auth, page?.q?? 1);
      }
  
    }, [fetchAdmins, auth, search]);
  
    useEffect(() => {
      setDataSource(admins?.data);
    }, [admins?.data]);

    const onTabChanged = (item) => {
        if (item.key) return false //remove later
        navigate(item.key);
    }

      const filterLogic = (searchText, entry) => {
          const item = searchText?.toLowerCase();
        
          let found = entry?.status?.toLowerCase()?.includes(item);
          found = found ? found : entry?.user_data?.full_name?.toLowerCase()?.includes(item);
          found = found ? found : entry?.phone_number?.toLowerCase()?.includes(item);
          found = found ? found : entry?.description?.toLowerCase()?.includes(item);
          found = found ? found : entry?.email?.toLowerCase()?.includes(item);
          found = found ? found : entry?.account_id?.toLowerCase()?.includes(item);
          found = found ? found : entry?.user_type?.type?.toLowerCase()?.includes(item);
          found = found ? found : entry?.status?.toLowerCase()?.includes(item);
          found = found
            ? found
            : entry?.user?.phone_number?.toLowerCase()?.includes(item);
          found = found ? found : entry?.created_at?.toLowerCase()?.includes(item);
    
          return found;
      };
    
      const handleSearch = useCallback(
          (currValue) => {
            setDataSource(Util.filterData(currValue, admins?.data, filterLogic));
          },
          [admins?.data]
      );

    return(
        <>
            <AddAdminModal visible={inviteAdminVisible} onCancel={() => showInviteAdmin(false)} />
            <Row className='mt-1'>
                <Col xs={12} sm={12} md={8}>
                    <NavTab 
                        defaultValue={pathname} 
                        data={adminData.tab}  
                        onClick={onTabChanged}
                        className='nav-tab-container'
                    />
                </Col>
                <Col xs={12} sm={12} md={4} className='text-right'>
                    <AgButton onClick={() => showInviteAdmin(true)} style={{marginRight: '8px'}} primary icon={<Icons.Add />}>Invite Admin</AgButton>
                </Col>
            </Row>

            <Block
                variant="div"
                background={Themes.white}
                padding="24px"
                borderRadius="8px"
                >
                <TableStatSearch
                    recordTitle="Total Admins Invited"
                    recordValue={dataSource?.length?? '___________'}
                    handleSearch={handleSearch}
                />
                <Row className="mt-2">
                    <Col xs={12}>  
                        <AgTable
                            useCheckedSelection={true}
                            preserveSelectedRowKeys={true}
                            rowKey="id"
                            columns={adminData.table.columns}
                            dataSource={dataSource}
                            loading={adminsLoading}
                            pagination={{
                                defaultCurrent: admins?.current_page?? 1,
                                pageSize: admins?.per_page?? 1,
                                total: admins?.total?? 1,
                                onChange: (pageNumber) => {
                                    navigate('?q=' + pageNumber);
                                }
                            }}
                        />
                    </Col>
                </Row>
            </Block>
        </>
    );
}

const structuredSelector = createStructuredSelector({
    auth: (state) => state.auth,
    adminsFetched: (state) => state?.app[adminConstants.fetchAdmins] ?? {}
  });
  
const mapDispatchToProps = (dispatch) => {
    return {
      fetchAdmins: (state, currentPage) => dispatch(adminActions.fetchAdmins(state, currentPage))
    };
};

export default connect(structuredSelector, mapDispatchToProps)(AdminListing);

