
import { Paths } from "../../routes/paths";

const table_columns = [
    {
      title: 'Account ID',
      dataIndex: 'account_id',
    },
    {
      title: 'Name',
      dataIndex: 'user_data',
      render: user_data => user_data?.full_name,
      width: 200
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      width: 150
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      width: 150
    },
    {
      title: 'User Type',
      dataIndex: 'user_type',
      render: user_type => user_type?.type
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 100,
      render: (status) => <span className={`status-before-content shape-circle ${status}`}>{status?? 'N/A'}</span>
    },
    {
      title: 'Total Logins',
      dataIndex: 'total_logins',
      width: 150
    },
    {
      title: 'Date Registered',
      dataIndex: 'created_at',
      width: 200
    },
  ];
  
const table_data = [
  
];

for (var i = 0; i < 100; i++) {
  let status = (i%2 === 0? true : false);

  table_data.push({
    id: i + 1, 
    name: 'raymond' + i, 
    username: 'raymond' + i, 
    email: 'raymond' + i + '@gmail.com', 
    date: (Math.floor(i/5) + 1 + Math.floor(Math.random() * 10)) + ' Mar 19:30 WAT', 
    access_type: 'Employ', 
    role: 'Default',
    added_by: 'Admin',
    total_logins: 10 * i + 1,
    status
  });
}


const tab_data = [
  {title: 'All Customers', key: Paths.customers}, 
  {title: 'Blocked List', key: Paths.customers + '/blocked-list'}, 
];

const userData = {
    table: {columns: table_columns, data: table_data},
    tab: tab_data
};

export default userData;



