import { Icons, Themes } from '../common/constants';
import { Paths } from './paths';



//Dashboard Sidebar Menu List
const sidebar_menu_list = [
    {
        path: Paths.dashboard_overview,
        icon: <Icons.Dashboard color={Themes.secondary} size={20} />,
        title: 'Overview'
    },
    {
        path: Paths.transaction_history,
        icon: <Icons.Transaction color={Themes.secondary} size={20} />,
        title: 'Transaction History'
    },
    {
        path: Paths.payments,
        icon: <Icons.Payments color={Themes.secondary} size={20} />,
        title: 'Payments'
    },
    {
        path: Paths.compliance,
        icon: <Icons.Compliance color={Themes.secondary} size={20} />,
        title: 'Compliance'
    },
    {
        path: Paths.user_management,
        icon: <Icons.Users color={Themes.secondary} size={20} />,
        title: 'User Management',
        submenu: [
            {
                path: Paths.customers,
                icon: <Icons.Users color={Themes.secondary} size={20} />,
                title: 'Customers',
            },
            {
                path: Paths.admins,
                icon: <Icons.Users color={Themes.secondary} size={20} />,
                title: 'Administrators',
            }
        ]
    },
    {
        path: Paths.services,
        icon: <Icons.Services color={Themes.secondary} size={20} />,
        title: 'Service Management'
    },
    {
        path: Paths.settings,
        icon: <Icons.Settings color={Themes.secondary} size={22} />,
        title: 'Settings'
    },
    /* {
        path: Paths.messaging,
        icon: <Icons.Messaging color={Themes.secondary} size={20} />,
        title: 'Messaging'
    }, */

];

export const menu = {
    sidebar_menu_list
}