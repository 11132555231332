import { Modal } from "antd";
import React from "react";

import LoginForm from './login-form';
import { Heading } from "./styles";

const RefreshLogin = ({refreshToken}) => {
    return(
        <Modal
            title="Session timeout"
            visible={refreshToken}
            footer={null}
            closable={false}
        >
            <Heading>Please enter your password to continue</Heading>
            <LoginForm />
        </Modal>
    );
}

export default RefreshLogin;
