
import { connect } from '../../api/connect';

const paymentApi = {
    fundWallet: async (authState, data) => {
        const response = await connect(authState).post(`admin/payment/fund-wallet`, data);
        return response;
    },
    fetchPaymentData: async (authState, pageNumber) => {
        const params = pageNumber? `page=${pageNumber}` : '';
        const response = await connect(authState).get(`admin/payments/${params}`);
        return response;
    },
    fetchPaymentDetail: async (authState, data) => {
        const { txRef } = data;
        const response = await connect(authState).get(`admin/payments/${txRef}`);
        return response;
    },
    cancelPayment: async(authState, id) => {
        alert("Cancel Payment");
        const response = await connect(authState).post(`v1/payments/${id}`, {
            status: 'Approved',
            reason: 'N/A'
        });
        return response;
    },
    downloadReport: async(authState, id) => {
        alert("Download Payment Report");
        const response = await connect(authState).post(`v1/payments/${id}`, {
            status: 'Declined',
            reason: 'N/A'
        });
        return response;
    }
}
  
export default paymentApi;