export const dashboardPrefix = '/dashboard';
export const authPrefix = '/auth';
export const passwordPrefix = authPrefix + '/password';
export const userManagementPrefix = dashboardPrefix + '/user-management';
export const settingsPrefix = dashboardPrefix + '/settings';

export const Paths = {
  login: authPrefix + '/login',
  login_success: authPrefix + '/login-success',
  create_account: authPrefix + '/account/create',
  forgot_password: passwordPrefix + '/forgot',
  reset_password: passwordPrefix + '/reset',
  password_reset_success: passwordPrefix + '/reset/success',
  dashboard_overview: dashboardPrefix + '/overview',
  transaction_history: dashboardPrefix + '/transaction-history',
  compliance: dashboardPrefix + '/compliance',
  settings: settingsPrefix,
  accounting: dashboardPrefix + '/accounting',
  payments: dashboardPrefix + '/payments',
  user_management: userManagementPrefix,
  customers: userManagementPrefix + '/customers',
  admins: userManagementPrefix + '/administrators',
  logout: dashboardPrefix + '/logout',
  api_providers: settingsPrefix + '/api-providers',
  my_account: settingsPrefix + '/my-account',
  services: dashboardPrefix + '/service-management',
  disbursement: dashboardPrefix + '/disbursement',
  messaging: dashboardPrefix + '/messaging',
  referrals: dashboardPrefix + '/referrals'
};
