import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';

import './App.less';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { GlobalStyles } from './common/components/styles';

import { AppRoutes } from './common/components';

import { store, persistor} from './state/store';


const App = () => {
  return(
    <Provider store={store}>
       <PersistGate loading={null} persistor={persistor}>
        <GlobalStyles />
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;


