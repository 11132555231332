import { customer } from '../../common/constants'



const fetchCustomers = (auth, pageNumber) => {
  return {
    type: customer.fetchCustomers,
    payload: {
      auth, pageNumber
    }
  }
}

const fetchCustomerDetail = (auth, accountId) => {
  return {
    type: customer.fetchCustomerDetail,
    payload: {
      auth, accountId
    }
  }
}


const addNewCustomer = (auth, data) => {
  return {
    type: customer.addNewCustomer,
    payload: {
      auth, data
    }
  }
}

const blockCustomer = (auth, data) => {
  return {
    type: customer.blockCustomer,
    payload: {
      auth, data
    }
  }
}


const Actions = {
  fetchCustomers,
  fetchCustomerDetail,
  addNewCustomer,
  blockCustomer
}

export default Actions;
