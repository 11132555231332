
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
//import { AddProviderModal, AgButton, AgTable, NavTab, TableStatSearch } from '../../common/components';

import { AgTable, TableStatSearch } from '../../common/components';
import { Block } from '../../common/components/styles';

//import { Icons, Themes } from '../../common/constants';
import { Themes } from '../../common/constants';

import { serviceActions, serviceConstants } from '../../state/service';
import { Util } from '../../util';
import serviceData from './service-data';



const ServiceListing = ({auth, fetchServices, servicesFetched}) => {
    const navigate = useNavigate();
    //const {search, pathname}= useLocation();
    const {search}= useLocation();
    const [dataSource, setDataSource] = useState([]);

    //const [addProviderVisible, showAddProvider] = useState(false);

    const {
        data: services,
        loading: servicesLoading
    } = servicesFetched?? {};
    
    useEffect(() => {
      if (!auth.loading && !auth.refresh_token) {
        const page = Util.queryString(search);
        fetchServices(auth, page?.q?? 1);
      }
  
    }, [fetchServices, auth, search]);
  
    useEffect(() => {
      setDataSource(services?.data);
    }, [services?.data]);

   /*  const onTabChanged = (item) => {
        if (item.key) return false //remove later
        navigate(item.key);
    }
 */
    const filterLogic = (searchText, entry) => {
        const item = searchText?.toLowerCase();
      
        let found = entry?.status?.toLowerCase()?.includes(item);
        found = found ? found : entry?.user_data?.full_name?.toLowerCase()?.includes(item);
        found = found ? found : entry?.phone_number?.toLowerCase()?.includes(item);
        found = found ? found : entry?.description?.toLowerCase()?.includes(item);
        found = found ? found : entry?.email?.toLowerCase()?.includes(item);
        found = found ? found : entry?.account_id?.toLowerCase()?.includes(item);
        found = found ? found : entry?.user_type?.type?.toLowerCase()?.includes(item);
        found = found ? found : entry?.status?.toLowerCase()?.includes(item);
        found = found
          ? found
          : entry?.user?.phone_number?.toLowerCase()?.includes(item);
        found = found ? found : entry?.created_at?.toLowerCase()?.includes(item);
  
        return found;
    };
  
    const handleSearch = useCallback(
        (currValue) => {
          setDataSource(Util.filterData(currValue, services?.data, filterLogic));
        },
        [services?.data]
    );

    return(
        <>
        {/** 
            <AddProviderModal visible={addProviderVisible} onCancel={() => showAddProvider(false)} />
            <Row className='mt-1'>
                <Col xs={12} sm={12} md={8}>
                    <NavTab 
                        defaultValue={pathname} 
                        data={serviceData.tab}  
                        onClick={onTabChanged}
                        className='nav-tab-container'
                    />
                </Col>
                <Col xs={12} sm={12} md={4} className='text-right'>
                    <AgButton onClick={() => showAddProvider(true)} style={{marginRight: '8px'}} primary icon={<Icons.Add />}>New Provider</AgButton>
                </Col>
            </Row>

        */}

            <Block
            variant="div"
            background={Themes.white}
            padding="24px"
            borderRadius="8px"
            >
            <TableStatSearch
                recordTitle="Total Services"
                recordValue={dataSource?.length?? '___________'}
                handleSearch={handleSearch}
            />
            <Row className="mt-2">
                <Col xs={12}>  
                    <AgTable
                        useCheckedSelection={true}
                        preserveSelectedRowKeys={true}
                        rowKey="id"
                        columns={serviceData.table.columns}
                        dataSource={dataSource}
                        loading={servicesLoading}
                        pagination={{
                            defaultCurrent: services?.current_page?? 1,
                            pageSize: services?.per_page?? 1,
                            total: services?.total?? 1,
                            onChange: (pageNumber) => {
                                navigate('?q=' + pageNumber);
                            }
                        }}
                    />
                </Col>
            </Row>
        </Block>
        </>
    );
}

const structuredSelector = createStructuredSelector({
    auth: (state) => state.auth,
    servicesFetched: (state) => state?.app[serviceConstants.fetchServices] ?? {}
  });
  
const mapDispatchToProps = (dispatch) => {
    return {
      fetchServices: (state, currentPage) => dispatch(serviceActions.fetchServices(state, currentPage))
    };
};

export default connect(structuredSelector, mapDispatchToProps)(ServiceListing);

