import { Typography } from "antd";
import React from "react";

import { Row, Col, Grid } from "react-flexbox-grid";
import AgSearchInput from "./search_input";

const TableStatSearch = ({
  recordTitle,
  recordValue,
  showSearchBox = true,
  handleSearch
}) => {
  return (
    <Grid>
      <Row>
        <Col md={9}>
          <Typography.Text style={{ fontSize: 12 }}>
            {recordTitle}
          </Typography.Text>
          <Typography.Title level={4} style={{ marginTop: 4 }}>
            {recordValue}
          </Typography.Title>
        </Col>

        <Col md={3}>
          {showSearchBox && (
            <AgSearchInput
              onChange={(e) => handleSearch(e.target.value)}
              placeholder="Search here..."
              className="search-input mb-1"
            />
          )}
        </Col>
      </Row>
    </Grid>
  );
};

export default TableStatSearch;
