
import { connect } from '../../api/connect';

const ComplianceApi = {
    fetchData: async (authState, pageNumber) => {
        const response = await connect(authState).get(`v1/get_compliance_list?page=${pageNumber}`);
        return response;
    },
    approve: async(authState, id) => {
        const response = await connect(authState).post(`v1/process_merchant_compliance/${id}`, {
            status: 'Approved',
            reason: 'N/A'
        });
        return response;
    },
    decline: async(authState, id) => {
        const response = await connect(authState).post(`v1/process_merchant_compliance/${id}`, {
            status: 'Declined',
            reason: 'N/A'
        });
        return response;
    },
    uploadDocs: async(authState, payload) => {
        console.log(payload);
        const response = await connect(authState).post(`v1/compliance_documents_upload/`, payload);
        return response;
    },
    downloadDocs: async(authState, payload) => {
        const response = await connect(authState).post(`v1/compliance_documents_upload/`, payload);
        return response;
    }
}
  
export default ComplianceApi;