import { DatePicker } from 'antd';
import React from 'react';

//import { Icons } from '../constants';

const { RangePicker } = DatePicker;

const DateRangePicker = () => {
    return (
        <RangePicker className='date-ranger-picker' />
    )
}

export default DateRangePicker;