import React, { useLayoutEffect } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { useLocation } from 'react-router-dom';
import { CustomerInfoCard, PaymentInfoCard } from '../../common/components';

const PaymentDetail = () => {
    const { state } = useLocation();
    const { paymentReference, accountId } = state;

    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [paymentReference]);
    
    return(
        <Grid>
               <Row>
                   <Col md={11}>
                        <PaymentInfoCard txRef={paymentReference} />  
                        <CustomerInfoCard accountId={accountId} />
                   </Col>
               </Row>
           </Grid>
    );
}

export default PaymentDetail;