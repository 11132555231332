import { all, call, put, takeLatest } from 'redux-saga/effects';
import customerApi from './customer.api';
import { customer } from '../../common/constants';
import { commonActions } from '../common/actions';
import { errorAsyncSaga } from '../common/sagas';


function* asyncFetchCustomers(action) {
  yield put(commonActions.loading(customer.fetchCustomers));
  try {
    const response = yield call(customerApi.fetchCustomers, action.payload.auth, action.payload.pageNumber);
    yield put(commonActions.success(customer.fetchCustomers, response.data));
  }
  catch(err) {
    const errorData = err?.response?.data?? {message: 'An unknown error has occurred!'};
    yield call(errorAsyncSaga, {actionName: customer.fetchCustomers, error: errorData, currentPage: action.payload.pageNumber});
  }
}

export function* watchFetchCustomersSaga() {
  yield takeLatest(customer.fetchCustomers, asyncFetchCustomers);
}

function* asyncFetchCustomerDetail(action) {
  yield put(commonActions.loading(customer.fetchCustomerDetail));
  try {
    const response = yield call(customerApi.fetchCustomerDetail, action.payload.auth, action.payload.accountId);
    yield put(commonActions.success(customer.fetchCustomerDetail, response.data));
  }
  catch(err) {
    const errorData = err?.response?.data?? {message: 'An unknown error has occurred!'};
    yield call(errorAsyncSaga, {actionName: customer.fetchCustomerDetail, error: errorData});
  }
}

export function* watchFetchCustomerDetailSaga() {
  yield takeLatest(customer.fetchCustomerDetail, asyncFetchCustomerDetail);
}

function* asyncAddNewCustomer(action) {
  yield put(commonActions.loading(customer.addNewCustomer));

  try {
    const response = yield call(customerApi.addNewCustomer, action.payload.auth, action.payload.data);
    yield put(commonActions.success(customer.addNewCustomer, response.data));
  }
  catch(err) {
    const errorData = err?.response?.data?? {message: 'An unknown error has occurred!'};
    yield call(errorAsyncSaga, {actionName: customer.addNewCustomer, error: errorData});
  }
}

export function* watchAddNewCustomerSaga() {
  yield takeLatest(customer.addNewCustomer, asyncAddNewCustomer);
}

function* asyncBlockCustomer(action) {
  yield put(commonActions.loading(customer.blockCustomer));
  try {
    const response = yield call(customerApi.blockCustomer, action?.auth, action?.payload);
    yield put(commonActions.success(customer.blockCustomer, response.data));
  }
  catch(err) {
    const errorData = err?.response?.data?? {message: 'An unknown error has occurred!'};
    yield call(errorAsyncSaga, {actionName: customer.blockCustomer, error: errorData})
  }
}

export function* watchBlockCustomerSaga() {
  yield takeLatest(customer.blockCustomer, asyncBlockCustomer);
}



export default function* customerSagas() {
  yield all([
    watchFetchCustomersSaga(),
    watchFetchCustomerDetailSaga(),
    watchBlockCustomerSaga(),
    watchAddNewCustomerSaga()
  ])
}

