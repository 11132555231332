
import React from 'react';
import { Themes } from '../../constants';


export const AgClose = ({size = 14, color = '#161616', color3='#fff', ...rest}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M15.833 5.342l-1.175-1.175L10 8.825 5.34 4.167 4.167 5.342 8.825 10l-4.658 4.658 1.175 1.175L10 11.175l4.658 4.658 1.175-1.175L11.175 10l4.658-4.658z"
        fill={color}
      />
    </svg>
  );
}

export const AgEnvelope = ({size = 14, color = '#161616', color2='#000', color3='#fff', ...rest}) =>  {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M2.5 2.5h10c.688 0 1.25.563 1.25 1.25v7.5c0 .688-.563 1.25-1.25 1.25h-10c-.688 0-1.25-.563-1.25-1.25v-7.5c0-.688.563-1.25 1.25-1.25z"
        fill={color}
        stroke={color2}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.75 3.75L7.5 9.375 1.25 3.75"
        stroke={color3}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}



export const AgLogout = ({size = 14, color = '#161616', ...rest}) =>  {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 2.25A.75.75 0 013 3v9a.75.75 0 11-1.5 0V3a.75.75 0 01.75-.75zm5.78 2.47a.75.75 0 010 1.06l-.97.97h5.69a.75.75 0 110 1.5H7.06l.97.97a.75.75 0 01-1.06 1.06L4.72 8.03a.75.75 0 010-1.06l2.25-2.25a.75.75 0 011.06 0z"
        fill="#161616"
      />
    </svg>
  );
}


export const AgAudit = ({size = 14, color = '#100658', color2='#fff', ...rest}) =>  {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25 6.5A2.75 2.75 0 017 3.75h1v1.5H7c-.69 0-1.25.56-1.25 1.25v14c0 .69.56 1.25 1.25 1.25h10c.69 0 1.25-.56 1.25-1.25v-14c0-.69-.56-1.25-1.25-1.25h-1v-1.5h1a2.75 2.75 0 012.75 2.75v14A2.75 2.75 0 0117 23.25H7a2.75 2.75 0 01-2.75-2.75v-14z"
        fill={color2}
      />
      <path fill={color2} d="M5.333 4.667h13.333V22H5.333z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 .75c-1.158 0-2.302.671-2.646 1.743A2.751 2.751 0 007.25 5.167V6.5c0 .414.336.75.75.75h8a.75.75 0 00.75-.75V5.167a2.751 2.751 0 00-2.104-2.674C14.302 1.421 13.158.75 12 .75zM15.495 11.436a.75.75 0 01.069 1.059l-3.512 4a.75.75 0 01-1.094.036L8.47 14.047a.75.75 0 011.06-1.061l1.922 1.918 2.984-3.399a.75.75 0 011.059-.069z"
        fill={color}
      />
    </svg>
  );
}

export const AgSorting = ({size = 14, color = '#4834D4', color2='#9A9FBF', ...rest}) =>  {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M4.43.956a.85.85 0 011.38 0l2.547 3.529a.85.85 0 01-.69 1.348H2.573a.85.85 0 01-.69-1.348L4.43.955z"
        fill={color}
      />
      <path
        d="M4.43 12.655a.85.85 0 001.38 0l2.547-3.529a.85.85 0 00-.69-1.348H2.573a.85.85 0 00-.69 1.348l2.547 3.529z"
        fill={color2}
      />
    </svg>
  );
}



export const AgAddUser = ({size = 14, color = '#000', ...rest}) =>  {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M6 6a3 3 0 100-6 3 3 0 000 6zm0 2a6 6 0 016 6H0a6 6 0 016-6zm8-4a1 1 0 00-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 002 0V7h1a1 1 0 100-2h-1V4z"
        fill={color}
      />
    </svg>
  );
}


export const AgTimes = ({size = 14, color = '#000', ...rest}) =>  {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.152.723a1.2 1.2 0 011.696 0L8 5.874 13.152.723a1.2 1.2 0 111.696 1.697L9.697 7.57l5.151 5.152a1.2 1.2 0 01-1.696 1.697L8 9.268 2.848 14.42a1.2 1.2 0 01-1.696-1.697L6.303 7.57 1.152 2.42a1.2 1.2 0 010-1.697z"
        fill={color}
        fillOpacity={0.5}
      />
      <path
        d="M1.08.652h0a1.3 1.3 0 000 1.838h0l5.082 5.081-5.081 5.081h0l-.001.001a1.3 1.3 0 001.838 1.838h.001L8 9.408l5.08 5.081h0l.002.002a1.3 1.3 0 001.838-1.839h0L9.837 7.57l5.08-5.08A1.3 1.3 0 1013.08.653L8 5.733 2.92.653h0a1.3 1.3 0 00-1.84 0z"
        stroke={color}
        strokeOpacity={0.5}
        strokeWidth={0.2}
      />
    </svg>
  );
}


export const AgFilter = ({size = 14, color = '#000', ...rest}) =>  {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M1.2 11a.8.8 0 000 1.6h5.814a1.6 1.6 0 002.772 0H10.8a.8.8 0 100-1.6H9.786a1.6 1.6 0 00-2.772 0H1.2zm0-4.8a.8.8 0 000 1.6h1.014a1.6 1.6 0 002.772 0H10.8a.8.8 0 100-1.6H4.985a1.6 1.6 0 00-2.77 0H1.2zm-.8-4a.8.8 0 01.8-.8h5.814a1.6 1.6 0 012.772 0H10.8a.8.8 0 110 1.6H9.786a1.6 1.6 0 01-2.772 0H1.2a.8.8 0 01-.8-.8z"
        fill={color}
      />
    </svg>
  );
}

export const AgSearch = ({size = 14, color = '#000', ...rest}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M13 13L9 9m1.333-3.333a4.667 4.667 0 11-9.334 0 4.667 4.667 0 019.334 0z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export const AgDashboard = ({size = 14, color = '#000', ...rest}) => {
  return (
    <svg
        width={size}
        height={size}
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
        >
            <path
                d="M9.602.842a28.15 28.15 0 00-6.204 0 2.873 2.873 0 00-2.535 2.51 27.1 27.1 0 000 6.296 2.874 2.874 0 002.535 2.51c2.045.229 4.159.229 6.204 0a2.874 2.874 0 002.535-2.51 27.104 27.104 0 000-6.296A2.874 2.874 0 009.602.842zM9.602 15.842a28.148 28.148 0 00-6.204 0 2.874 2.874 0 00-2.535 2.51 27.1 27.1 0 000 6.296 2.874 2.874 0 002.535 2.51c2.045.229 4.159.229 6.204 0a2.874 2.874 0 002.535-2.51 27.103 27.103 0 000-6.296 2.874 2.874 0 00-2.535-2.51zM24.602.842a28.15 28.15 0 00-6.204 0 2.873 2.873 0 00-2.535 2.51 27.093 27.093 0 000 6.296 2.873 2.873 0 002.535 2.51c2.045.229 4.159.229 6.204 0a2.874 2.874 0 002.535-2.51 27.104 27.104 0 000-6.296 2.874 2.874 0 00-2.535-2.51zM24.602 15.842a28.148 28.148 0 00-6.204 0 2.873 2.873 0 00-2.535 2.51 27.092 27.092 0 000 6.296 2.873 2.873 0 002.535 2.51c2.045.229 4.159.229 6.204 0a2.874 2.874 0 002.535-2.51 27.103 27.103 0 000-6.296 2.874 2.874 0 00-2.535-2.51z"
                fill={color}
            />
    </svg>
  );
}


export const AgStatistics = ({size = 14, color = '#000', ...rest}) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M2.803 9.909H1.44c-.75 0-1.363.886-1.363 1.97v5.909c0 1.083.613 1.97 1.363 1.97h1.364c.75 0 1.364-.887 1.364-1.97v-5.91c0-1.083-.614-1.969-1.364-1.969zM10.682.06H9.318c-.75 0-1.363.887-1.363 1.97v15.758c0 1.083.613 1.97 1.363 1.97h1.364c.75 0 1.364-.887 1.364-1.97V2.03c0-1.083-.614-1.97-1.364-1.97zM18.56 5.97h-1.363c-.75 0-1.363.886-1.363 1.97v9.848c0 1.083.613 1.97 1.363 1.97h1.364c.75 0 1.363-.887 1.363-1.97V7.939c0-1.083-.613-1.97-1.363-1.97z"
                fill={color}
            />
        </svg>
    );
}


export const AgTransactionDArrow = ({color=Themes.dark, size=14, ...rest}) => {
    return(
        <svg
            width={size}
            height={size}
            viewBox="0 0 21 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M18.216 7.09a1.25 1.25 0 100-2.5H4.36l1.612-1.618A1.25 1.25 0 004.21 1.21L.46 4.96a1.25 1.25 0 00-.275 1.362 1.25 1.25 0 001.156.769h16.875zM19.466 11.466H2.591a1.25 1.25 0 000 2.5h13.856l-1.612 1.619a1.251 1.251 0 001.357 2.074 1.25 1.25 0 00.405-.312l3.75-3.75a1.25 1.25 0 00.275-1.362 1.25 1.25 0 00-1.156-.769z"
                fill={color}
            />
        </svg>
    );
}

export const AgRevenue = ({color=Themes.dark, size=14, ...rest}) => {
    return(
        <svg {...rest} preserveAspectRatio="xMinYMin meet" width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.80308 11.909H2.43944C1.68944 11.909 1.07581 12.7954 1.07581 13.8787V19.7878C1.07581 20.8712 1.68944 21.7575 2.43944 21.7575H3.80308C4.55308 21.7575 5.16671 20.8712 5.16671 19.7878V13.8787C5.16671 12.7954 4.55308 11.909 3.80308 11.909Z" fill={color}/>
            <path d="M11.682 2.06059H10.3183C9.56835 2.06059 8.95471 2.94696 8.95471 4.03029V19.7879C8.95471 20.8712 9.56835 21.7576 10.3183 21.7576H11.682C12.432 21.7576 13.0456 20.8712 13.0456 19.7879V4.03029C13.0456 2.94696 12.432 2.06059 11.682 2.06059Z" fill={color}/>
            <path d="M19.5608 7.96968H18.1971C17.4471 7.96968 16.8335 8.85604 16.8335 9.93938V19.7879C16.8335 20.8712 17.4471 21.7576 18.1971 21.7576H19.5608C20.3108 21.7576 20.9244 20.8712 20.9244 19.7879V9.93938C20.9244 8.85604 20.3108 7.96968 19.5608 7.96968Z" fill={color}/>
        </svg>
    );
}


export const AgChat = ({color=Themes.dark, size=14, ...rest}) => {
    return(
        <svg {...rest} preserveAspectRatio="xMinYMin meet" width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.6666 1.66672H2.99996C2.26663 1.66672 1.67329 2.26672 1.67329 3.00005L1.66663 15L4.33329 12.3334H13.6666C14.4 12.3334 15 11.7334 15 11.0001V3.00005C15 2.26672 14.4 1.66672 13.6666 1.66672ZM4.33329 6.33338H12.3333V7.66672H4.33329V6.33338ZM9.66663 9.66672H4.33329V8.33338H9.66663V9.66672ZM12.3333 5.66672H4.33329V4.33338H12.3333V5.66672Z" fill={color}/>
        </svg>
    );
}

export const AgNotification = ({color=Themes.dark, size=14, ...rest}) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <path
          d="M12 3.333a5.939 5.939 0 00-4.243 1.794A6.19 6.19 0 006 9.458v3.661l-.707.722a1.04 1.04 0 00-.217 1.112c.076.187.204.346.368.458a.987.987 0 00.556.172h12c.198 0 .391-.06.556-.172.164-.112.292-.271.368-.458a1.04 1.04 0 00-.217-1.112L18 13.119V9.46a6.19 6.19 0 00-1.757-4.332A5.94 5.94 0 0012 3.333zm0 16.334a2.97 2.97 0 01-2.121-.897 3.095 3.095 0 01-.88-2.166H15c0 .812-.316 1.591-.879 2.166a2.97 2.97 0 01-2.121.897z"
          fill={color}
        />
      </svg>
    );
}


export const AgChevronDown = ({color=Themes.dark, size=14, ...rest}) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 13 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M.352.752a1.2 1.2 0 011.696 0L6 4.703 9.952.752a1.2 1.2 0 111.696 1.696l-4.8 4.8a1.2 1.2 0 01-1.696 0l-4.8-4.8a1.2 1.2 0 010-1.696z"
          fill={color}
          fillOpacity={rest?.fillOpacity?? 1}
        />
      </svg>
    );
  }
  

export const AgComment = ({color=Themes.dark, size=14, ...rest}) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <path
          d="M16.667 5.667H6c-.733 0-1.327.6-1.327 1.333l-.006 12 2.666-2.667h9.334c.733 0 1.333-.6 1.333-1.333V7c0-.733-.6-1.333-1.333-1.333zm-9.334 4.666h8v1.334h-8v-1.334zm5.334 3.334H7.333v-1.334h5.334v1.334zm2.666-4h-8V8.333h8v1.334z"
          fill={color}
        />
      </svg>
    );
}
  


export const AgRefresh = ({color=Themes.dark, size=14, ...rest}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M3.834 12.933a.635.635 0 00.52-.276v-.001L6.71 9.248s0 0 0 0a.625.625 0 00.04-.654h0l-.002-.003a.629.629 0 00-.237-.242l-.125.217.125-.217a.63.63 0 00-.324-.084H4.622a7.4 7.4 0 012.606-4.227h0c1.397-1.136 3.144-1.788 4.786-1.788 2.227 0 4.599 1.2 6.035 3.064v.002a.635.635 0 001.005-.775h0l-.002-.003a9.367 9.367 0 00-3.219-2.628C14.608 1.3 13.287.98 12.014.98c-1.945 0-3.978.765-5.585 2.077C4.782 4.4 3.693 6.227 3.332 8.265H1.478a.624.624 0 00-.524.982l2.356 3.415h0a.63.63 0 00.23.2l.114-.221-.115.222a.63.63 0 00.295.07zm0 0h-.001l-.001-.25.003.25h-.001zm13.979-1.198h1.564a7.4 7.4 0 01-2.605 4.227h0c-1.398 1.136-3.144 1.788-4.786 1.788-2.227 0-4.6-1.2-6.035-3.064h0l-.001-.002a.634.634 0 00-.887-.11l.153.197-.153-.198a.634.634 0 00-.117.886h0l.001.003a9.368 9.368 0 003.22 2.628c1.224.61 2.546.931 3.819.931 1.945 0 3.978-.765 5.585-2.077 1.647-1.343 2.736-3.17 3.096-5.209h1.855a.63.63 0 00.562-.326v-.001a.617.617 0 00-.038-.65L20.69 7.344v-.001a.632.632 0 00-1.046 0s0 0 0 0l-2.355 3.413a.616.616 0 00-.04.651l.002.001a.63.63 0 00.561.326z"
        fill={color}
        stroke={color}
        strokeWidth={0.5}
      />
    </svg>
  );
}

export const AgCompliance = ({color=Themes.dark, size=14, ...rest}) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <path
          d="M9.16.407a2.25 2.25 0 00-2.32 0l-.517.311a9.75 9.75 0 01-4.115 1.354l-.325.031A1.25 1.25 0 00.75 3.347v1.644a10.25 10.25 0 003.126 7.37l3.255 3.147a1.25 1.25 0 001.738 0l3.255-3.147a10.25 10.25 0 003.126-7.37V3.347a1.25 1.25 0 00-1.133-1.244l-.325-.03A9.75 9.75 0 019.677.717l-.516-.31z"
          fill={color}
        />
      </svg>
    );
  }

export const AgTransaction = ({color=Themes.dark, size=14, ...rest}) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.965 12.56c-1.37.837-3.204 1.299-5.161 1.299-1.464 0-2.858-.258-4.04-.739.143.005.287.008.432.008 2.145 0 4.17-.516 5.7-1.451 1.61-.985 2.496-2.32 2.496-3.761v-1.43c.199.098.39.203.573.314 1.29.79 2.002 1.812 2.002 2.88 0 1.067-.711 2.09-2.002 2.88zM3.035 8.09c-1.29-.79-2.002-1.812-2.002-2.88 0-1.067.711-2.09 2.002-2.879 1.37-.838 3.204-1.3 5.161-1.3 1.958 0 3.79.462 5.161 1.3 1.291.79 2.002 1.812 2.002 2.88 0 1.067-.71 2.09-2.002 2.88-1.37.837-3.203 1.299-5.16 1.299-1.958 0-3.791-.462-5.162-1.3zM20 9.68c0-1.441-.887-2.777-2.496-3.761a8.625 8.625 0 00-1.112-.568v-.14c0-1.44-.886-2.776-2.496-3.76C12.366.515 10.341 0 8.196 0s-4.17.515-5.7 1.451C.886 2.435 0 3.771 0 5.211v2.705c0 1.44.887 2.776 2.496 3.76.347.212.72.401 1.112.569v.139c0 1.44.886 2.776 2.496 3.76 1.53.936 3.555 1.452 5.7 1.452s4.17-.516 5.7-1.451c1.61-.985 2.496-2.32 2.496-3.761V9.74h-.001L20 9.68z"
          fill={color}
        />
      </svg>
    );
}


export const AgUsers = ({color=Themes.dark, size=14, ...rest}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M13.004 9.355c1.488 0 2.951-2.611 2.951-5.27 0-2.774-1.607-3.358-2.951-3.358-1.352 0-2.951.584-2.951 3.359 0 2.658 1.46 5.269 2.951 5.269zm4.948 1.98c-.62-.207-1.349-.541-2.152-.997a29.839 29.839 0 01-1.197-.734c-.477.4-1.018.636-1.599.636-.58 0-1.122-.237-1.603-.636a18.804 18.804 0 01-1.83 1.074c-.56.287-1.065.506-1.519.657-1.646.554-2.064 2.933-2.163 3.938h14.226c-.103-1-.513-3.38-2.163-3.938zm2.569-2.38c.302 0 .597-.15.867-.407.012-.01.02-.022.032-.03.68-.675 1.201-2.023 1.201-3.393 0-1.984-1.145-2.4-2.104-2.4-.962 0-2.108.416-2.108 2.4 0 1.374.529 2.731 1.213 3.401.287.266.593.43.899.43zm3.679 1.701c-.461-.15-.99-.395-1.63-.751-.327-.185-.617-.37-.844-.516-.362.284-.771.455-1.201.455-.438 0-.843-.167-1.205-.455a13.816 13.816 0 01-1.61.915c.17.073.333.129.488.18 1.337.451 2.029 1.722 2.387 2.9h5.142c-.091-.787-.417-2.35-1.527-2.728zM4.612 8.543c.27.258.569.408.867.408.306 0 .608-.154.887-.42l.016-.018c.684-.674 1.205-2.023 1.205-3.392 0-1.985-1.146-2.401-2.108-2.401-.959 0-2.104.416-2.104 2.4 0 1.366.517 2.715 1.197 3.389.016.013.028.021.04.034zM7.81 10.49a5.68 5.68 0 00.489-.18c-.239-.117-.493-.25-.772-.4a13.42 13.42 0 01-.843-.515c-.362.283-.768.455-1.201.455-.434 0-.84-.168-1.201-.455a12.59 12.59 0 01-.847.515c-.629.356-1.166.601-1.623.751-1.118.374-1.444 1.937-1.54 2.727H5.42c.358-1.18 1.05-2.452 2.39-2.898z"
        fill={color}
      />
    </svg>
  );
}


export const AgUser = ({color=Themes.dark, size=14, ...rest}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 7.8A3.6 3.6 0 109 .6a3.6 3.6 0 000 7.2zM.6 18.6a8.4 8.4 0 1116.8 0H.6z"
        fill={color}
      />
    </svg>
  );
}

export const AgGateway = ({color=Themes.dark, size=14, ...rest}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M.09 4.03A3.94 3.94 0 014.03.09h7.273a3.94 3.94 0 013.94 3.94.91.91 0 11-1.819 0c0-1.171-.95-2.12-2.121-2.12H4.03c-1.171 0-2.121.949-2.121 2.12v7.273c0 1.171.95 2.121 2.121 2.121h7.095a3.335 3.335 0 016.542.91 3.333 3.333 0 01-6.542.908H4.03a3.94 3.94 0 01-3.94-3.939V4.03zm17.88 4.546c1.171 0 2.12.95 2.12 2.121v7.273c0 1.171-.949 2.12-2.12 2.12h-7.273a2.121 2.121 0 01-2.121-2.12.91.91 0 10-1.819 0 3.94 3.94 0 003.94 3.94h7.273a3.94 3.94 0 003.939-3.94v-7.273a3.94 3.94 0 00-3.94-3.94h-7.095a3.335 3.335 0 00-6.54.91 3.333 3.333 0 006.54.909h7.096z"
        fill={color}
      />
    </svg>
  );
}

 
export const AgDisbursement = ({color=Themes.dark, size=14, ...rest}) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.2 3h21.6c.663 0 1.2.672 1.2 1.5v15c0 .828-.537 1.5-1.2 1.5H1.2C.537 21 0 20.328 0 19.5v-15C0 3.672.537 3 1.2 3zm.6 12.75v3h2.4c0-1.657-1.074-3-2.4-3zm0-10.5v3c1.326 0 2.4-1.343 2.4-3H1.8zm18 0c0 1.657 1.074 3 2.4 3v-3h-2.4zM12 15.857c1.988 0 3.6-1.727 3.6-3.857S13.988 8.143 12 8.143 8.4 9.87 8.4 12s1.612 3.857 3.6 3.857z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.813 18.938a.563.563 0 01-1.125 0v-2.123l-.752.668a.562.562 0 11-.747-.84l1.687-1.5a.562.562 0 01.748 0l1.687 1.5a.563.563 0 01-.747.84l-.751-.668v2.122z"
          fill={rest?.color2?? '#fff'}
        />
      </svg>
    );
  }
  
  


export const AgAccounting = ({color=Themes.dark, size=14, ...rest}) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 20 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17 18.5H4A3.5 3.5 0 01.5 15V1.943C.5.876 1.556.2 2.485.521c.133.046.263.113.387.202l.175.125A2.51 2.51 0 005.959.843a3.52 3.52 0 014.082 0 2.51 2.51 0 002.912.005l.175-.125c.993-.71 2.372 0 2.372 1.22V9.5H19a.75.75 0 01.75.75v5.5A2.75 2.75 0 0117 18.5zM15.75 11v4.75a1.25 1.25 0 102.5 0V11h-2.5zM11.5 6.75a.75.75 0 00-.75-.75h-6a.75.75 0 000 1.5h6a.75.75 0 00.75-.75zm-1 3A.75.75 0 009.75 9h-5a.75.75 0 000 1.5h5a.75.75 0 00.75-.75zm.25 2.25a.75.75 0 010 1.5h-6a.75.75 0 010-1.5h6z"
          fill={color}
        />
      </svg>
    );
}

export const AgSettings = ({color=Themes.dark, size=14, ...rest}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.788 1.804c-.456-1.872-3.12-1.872-3.576 0a1.838 1.838 0 01-2.743 1.138c-1.647-1.004-3.53.88-2.527 2.527a1.84 1.84 0 01-1.137 2.744c-1.873.455-1.873 3.12 0 3.574a1.838 1.838 0 011.137 2.744c-1.004 1.647.88 3.53 2.527 2.527a1.839 1.839 0 012.744 1.137c.455 1.873 3.12 1.873 3.574 0a1.84 1.84 0 012.744-1.137c1.647 1.004 3.53-.88 2.527-2.527a1.841 1.841 0 011.137-2.744c1.873-.455 1.873-3.12 0-3.574a1.84 1.84 0 01-1.137-2.744c1.004-1.647-.88-3.53-2.527-2.527a1.838 1.838 0 01-2.744-1.137l.001-.001zM10 13.6a3.6 3.6 0 100-7.2 3.6 3.6 0 000 7.2z"
        fill={color}
      />
    </svg>
  );
}


