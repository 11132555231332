import React from 'react';

import { Body1, Logo, StyledBox, Anchor } from '../../common/components/styles';
import { Themes, Images } from '../../common/constants';

import { Grid } from 'antd';
import { Paths } from '../../routes/paths';


const { useBreakpoint } = Grid;

const PublicLayout = ({children, logo, bgBody = Themes.body1, containerPadding, ...rest}) => {
    const screen = useBreakpoint();

    const containerProps = {
        backgroundColor: Themes.white,
        margin: "1.5rem auto",
        padding: containerPadding?? (screen.xs? "30px 35px" : "60px 45px"),
        width: screen.xs? '380px' : '450px',
        radius: '16px'
    }

    return(
        <Body1 background={bgBody}>
            <StyledBox textAlign='center' margin="5rem auto">
                <Anchor href={Paths.login}>{logo?? <Logo src={Images.logo_dark} width='160px' />}</Anchor>
                <StyledBox 
                    style={{position: 'relative'}}
                    {...containerProps}
                    {...rest}
                >
                        {children}
                </StyledBox>
            </StyledBox>
        </Body1>
    );
}

export default PublicLayout;