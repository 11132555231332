
import { Widget } from '../../common/constants';

const initialState = {
   sidebar_collapsed: false
}

const widgetReducer = (state = initialState, action) => {
    switch (action.type) {
        case Widget.sidebar_collapsed:
            return {...state, sidebar_collapsed: action.payload};
        default:
            return state
    }
}

export default widgetReducer;