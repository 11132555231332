import { all, call, put, takeLatest } from 'redux-saga/effects';
import adminApi from './admin.api';
import { admin } from '../../common/constants';

import { NotificationManager } from 'react-notifications';
import { commonActions } from '../common/actions';
import { errorAsyncSaga } from '../common/sagas';


function* asyncFetchAdmins(action) {
  yield put(commonActions.loading(admin.fetchAdmins));

  try {
    const response = yield call(adminApi.fetchAdmins, action.payload.auth, action.payload.pageNumber);
    yield put(commonActions.success(admin.fetchAdmins, response.data));
  }
  catch(err) {
    const errorData = err?.response?.data?? {message: 'An unknown error has occurred!'};
    yield call(errorAsyncSaga, {actionName: admin.fetchAdmins, error: errorData, currentPage: action.payload.pageNumber});
  }
}

export function* watchFetchAdminsSaga(action) {
  yield takeLatest(admin.fetchAdmins, asyncFetchAdmins);
}

function* asyncAddNewAdmin(action) {
  yield put(commonActions.loading(admin.addNewAdmin));

  try {
    const response = yield call(adminApi.addNewAdmin, action.payload.auth, action.payload.data);
    yield put(commonActions.success(admin.addNewAdmin, response.data));
  }
  catch(err) {
    const errorData = err?.response?.data?? {message: 'An unknown error has occurred!'};
    yield call(errorAsyncSaga, {actionName: admin.addNewAdmin, error: errorData});
  }
}

export function* watchAddNewAdminSaga() {
  yield takeLatest(admin.addNewAdmin, asyncAddNewAdmin);
}


function* asyncApprove(action) {
  yield put(commonActions.loading(admin.approve));

  try {
    const response = yield call(adminApi.approve, action?.auth, action?.payload?.id);
    yield put(commonActions.success(admin.approve, response.data));
    NotificationManager.success(response?.data?.message);
  }
  catch(err) {
    const errorData = err?.response?.data?? {message: 'An unknown error has occurred!'};
    yield call(errorAsyncSaga, {actionName: admin.approve, error: errorData});
    NotificationManager.error(errorData?.message);
  }
}

export function* watchApproveSaga() {
  yield takeLatest(admin.approveAsync, asyncApprove);
}

function* asyncDecline(action) {
  yield put(commonActions.loading(admin.cancelAdmin));

  try {
    const response = yield call(adminApi.decline, action?.auth, action?.payload?.id);
    yield put(commonActions.success(admin.cancelAdmin, response.data));
    NotificationManager.success(response?.data?.message);
  }
  catch(err) {
    const errorData = err?.response?.data?? {message: 'An unknown error has occurred!'};
    yield call(errorAsyncSaga, {actionName: admin.cancelAdmin, error: errorData});
    NotificationManager.error(errorData?.message);
  }
}

export function* watchDeclineSaga() {
  yield takeLatest(admin.cancelAdminAsync, asyncDecline);
}

function* asyncUploadDocs(action) {
  yield put(commonActions.loading(admin.printReport));
  try {
    const response = yield call(adminApi.uploadDocs, action?.auth, action?.payload);
    yield put(commonActions.success(admin.printReport, response.data));
  }
  catch(err) {
    const errorData = err?.response?.data?? {message: 'An unknown error has occurred!'};
    yield call(errorAsyncSaga, {actionName: admin.printReport, error: errorData});
  }
}

export function* watchUploadDocsSaga() {
  yield takeLatest(admin.printReportAsync, asyncUploadDocs);
}

export default function* AdminSagas() {
  yield all([
    watchFetchAdminsSaga(),
    watchAddNewAdminSaga(),
    //watchApproveSaga(),
    //watchDeclineSaga(),
    //watchUploadDocsSaga()
  ])
}

