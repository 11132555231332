

const themes =  {
    primary: '#19C0FF',
    secondary: '#223344',
    gray: '#6c757d',
    dark: '#626465',
    success: '#11E53F',
    //body1: '#F4F5F7',
    body2: '#F4F5FA',
    white: '#FFFFFF',
    warning: '#ffc107',
    danger: '#ED420C',
    black: '#000000',
    focus: ''
}

export default themes;

