import React, { useEffect } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import InfoContainer from './info-container';
import Info from './info';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { transactionActions, transactionConstants } from "../../../state/transaction";
import { Util } from '../../../util';
import ButtonInput from '../button';
import { Icons, Themes } from '../../constants';
import Spinner from '../spinner';


const { fetchTransactionDetail } = transactionActions;


const TransactionInfo = ({auth, txnDataFetched, fetchTxnDetail, txRef}) => {
    const { loading, data: txnData } = txnDataFetched;

    useEffect(() => {
        if (!auth.loading && !auth.refresh_token)
            fetchTxnDetail(auth, {txRef});
    }, [fetchTxnDetail, auth, txRef]);

    const { 
        transaction_id, external_reference, user, quantity,
        debited_amount, pre_balance, post_balance, created_at,
        currency, service, provider, charge, amount, status
    } = txnData?? {};

    const StatusElement = () => {
        switch(status?.toLowerCase()) {
            case 'success':
                return <Icons.Success size={16} color={Themes.success} />;
            case 'failed':
                return <Icons.Failed size={16} color={Themes.danger} />
            default:
                return <Icons.Pending size={16} color={Themes.warning} />
        }
    }

    return(
        <Grid className='mt-1'>
            <InfoContainer loading={loading} className='mb-2'
                header={
                    <Row className='mb-1'>
                        <Col md={6} className='flex-middle'>
                            {loading? <Spinner loading={loading} /> : <>
                                <h2 className='h1'>{Util.formatAmount(debited_amount, currency?? '')} <StatusElement /></h2>
                                <h4 className='h4'>Transaction from <span className='wt-600'>{user?.user_data?.name?? user?.email}</span>
                                </h4>

                                <h2 className='h2 mt-1'>
                                    Date created: <span className='wt-600'>{created_at}</span>
                                </h2>
                            </>}
                        </Col>
                        <Col md={6} className='flex flex-middle flex-right py-1'>
                            <ButtonInput style={{marginRight: '8px'}} warning icon={<Icons.Accounting />}>Refund</ButtonInput>
                            <ButtonInput primary icon={<Icons.Accounting />}>Send Invoice</ButtonInput>
                        </Col>
                    </Row>   
                }
            >
                <Info xs={4} md={3} title={'Product'} description={service?.title?? 'N/A'} />
                <Info md={3} title={'Product Category'} description={service?.service_category?.title?? 'N/A'} />
                <Info md={3} title={'Provider'} description={provider?.name?? 'N/A'} />
                <Info xs={4} md={3} title={'Transaction ID'} description={transaction_id} />
                <Info md={3} title={'External Reference'} description={external_reference?? 'N/A'} />
                <Info md={3} title={'Quantity'} description={quantity} />
                <Info md={3} title={'Amount'} description={Util.formatAmount(amount, currency?? '')} />
                <Info xs={4} md={3} title={'Debited Amount'} description={Util.formatAmount(debited_amount, currency?? '')} />
                <Info md={3} title={'Transaction Fee'} description={Util.formatAmount(charge, currency, false)} />
                <Info md={3} title={'Pre. Balance'} description={Util.formatAmount(pre_balance, currency, false)} />
                <Info md={3} title={'Cur. Balance'} description={Util.formatAmount(post_balance, currency, false)} />
            </InfoContainer>
        </Grid>
    );
}

const structuredSelector = createStructuredSelector({
    auth: (state) => state.auth,
    txnDataFetched: (state) => state?.app[transactionConstants.fetchTransactionDetail]?? {},
});
  
const mapDispatchToProps = (dispatch) => {
    return {
      fetchTxnDetail: (state, data) => dispatch(fetchTransactionDetail(state, data))
    };
};
  
export default connect(structuredSelector, mapDispatchToProps)(TransactionInfo);