import Logo from '../../assets/img/logo.svg';
import LogoWithoutText from '../../assets/img/logo-without-text.svg';
import LogoDark from '../../assets/img/logo-dark.svg';
import Body1 from '../../assets/img/body1.svg';

import { GrFacebookOption, GrTwitter, GrInstagram, GrLinkedinOption } from 'react-icons/gr';

const Images = {
    logo: Logo,
    logo_without_text: LogoWithoutText,
    logo_dark: LogoDark,
    body1: Body1,
    facebook: <GrFacebookOption />,
    twitter: <GrTwitter />,
    instagram: <GrInstagram />,
    linkedin: <GrLinkedinOption />
}

export default Images;