import { Icons, Themes } from "../../common/constants";
import { Util } from "../../util";

const dashboardData = {};

dashboardData.counters = [
  {
    type: "success",
    title: "Total Revenue",
    balance: "50000000",
    icon: <Icons.Revenue color={Themes.success} size={20} />,
    body: {
      title: "+17.3%",
      label: "Up from Yesterday"
    }
  },
  {
    type: "warning",
    title: "Total Transactions",
    balance: "3000",
    icon: <Icons.TransactionDArrow color={Themes.warning} size={20} />,
    body: {
      title: "+17.3%",
      label: "Up from Yesterday"
    }
  },
  {
    type: "success",
    title: "Total Customers",
    balance: "3000",
    icon: <Icons.Users color={Themes.success} size={20} />,
    body: {
      title: "+17.3%",
      label: "Up from Yesterday"
    }
  },
  {
    type: "danger",
    title: "Service Providers",
    balance: "3000",
    icon: <Icons.Gateway color={Themes.danger} size={20} />,
    body: {
      title: "+17.3%",
      label: "Up from Yesterday"
    }
  }
];

const tab_data = [
  { title: "Daily", key: "daily" },
  { title: "Weekly", key: "weekly" },
  { title: "Monthly", key: "monthly" },
  { title: "Yearly", key: "yearly" }
];

const chart_data = [
  {
    date: "16th Feb",
    sales: "5000000"
  },
  {
    date: "16th Feb",
    sales: "1300"
  },
  {
    date: "18 Mar",
    sales: "3000"
  },
  {
    date: "19th Apr",
    sales: "100000"
  },
  {
    date: "13th May",
    sales: "9900"
  },
  {
    date: "22nd Jun",
    sales: "400000"
  },
  {
    date: "19th Jul",
    sales: "260000"
  },
  {
    date: "12th Aug",
    sales: "400000"
  },
  {
    date: "19th Oct",
    sales: "200000"
  },
  {
    date: "23rd Jun",
    sales: "400000"
  },
  {
    date: "29th Sept",
    sales: "1600"
  },
  {
    date: "2nd Nov",
    sales: "1700"
  },
  {
    date: "18th Dec",
    sales: "200"
  },
  {
    date: "2nd Nov",
    sales: "1500"
  },
  {
    date: "14th Dec",
    sales: "2000000"
  }
];

const chart_config = {
  data: chart_data,
  xField: "date",
  yField: "sales",
  yAxis: {
    label: {
      formatter: v => "₦" + Util.numFormat(v)
    }
  },
  xAxis: {
    label: {
      autoHide: true,
      autoRotate: false
    }
  },
  meta: {
    date: {
      alias: "Date"
    },
    sales: {
      alias: "Sales"
    }
  },

  minColumnWidth: 30,
  maxColumnWidth: 40,
  columnStyle: {
    radius: [8, 8, 8, 8]
  },
  color: [Themes.primary, Themes.primary]
};

dashboardData.tab_data = tab_data;
dashboardData.chart_config = chart_config;


export default dashboardData;
