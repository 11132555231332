import {Layout, Grid} from "antd";
import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Header from "./header";
import Sidebar from "./sidebar";
import { VSpace } from "./styles";

import RefreshLogin from './refresh-login';

import AgBreadcrumb from "./breadcrumb";
import { Outlet, useLocation } from "react-router-dom";
import { Paths } from "../../routes/paths";

const { Content } = Layout;
const { useBreakpoint } = Grid;


const DashboardLayout =  ({auth, pageTitle, pageIcon, sidebar_collapsed}) => {
    const screen = useBreakpoint();
    const location = useLocation();
    
    return(
        <Layout className="site-layout-background" hasSider>  

            <Sidebar 
                style={{
                    overflow: 'auto',
                    height: '100vh',
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    bottom: 0
                }} 
                className={`${(screen.xs && sidebar_collapsed)? 'w-0' : ''}`}
            />

            <Layout 
                className='site-layout-background'
                style={{
                    marginLeft: (screen.xs && sidebar_collapsed)? '0' : (sidebar_collapsed? '80px' : '278px')
                }}
            >
                <Header 
                    sidebar_collapsed_class={sidebar_collapsed? 'sidebar-collapsed' : ''} 
                    pageTitle={pageTitle} pageIcon={pageIcon} 
                    style={{ 
                        position: 'fixed', 
                        zIndex: 1, 
                        justifyContent: (screen.xs && sidebar_collapsed)? 'normal' : 'center'
                    }}  

                />
                <Content  
                    className='site-layout-background'
                    style={{
                        padding: '0 24px',
                        marginTop: 84,
                        minHeight: '100vh'
                    }}
                >
                    <RefreshLogin refreshToken={auth?.refresh_token?? false} />
                    <VSpace style={{padding: '0px 0px 40px 10px'}}>
                        <AgBreadcrumb path={location.pathname} dashboardPrefix={Paths.dashboard_overview} />
                    </VSpace>
                    <Outlet />
                    <VSpace space="40px" /> 
                </Content>
            </Layout>
        </Layout>
    );
}

const structuredSelector = createStructuredSelector({
    auth: state => state.auth,
    sidebar_collapsed: state => state.widget.sidebar_collapsed
})
  
const mapDispatchToProps = (dispatch) => {
    return { 
       
    }
}
  
  
export default connect(structuredSelector, mapDispatchToProps)(DashboardLayout);
