import { Select } from 'antd';
import React from 'react';
import * as PropTypes from 'prop-types';

import { Icons } from '../constants';

const { Option } = Select;

const SelectInput = ({placeholder, data, suffixIcon, ...rest}) => {
    return (
        <Select  
            placeholder={placeholder}
            suffixIcon={suffixIcon?? <Icons.DownArrow className="stroke" />}  
            {...rest}   
        >
            {
                data?.map((item, index) => <Option value={item.key}>{item.title}</Option>)
            }
            
        </Select>
    )
}

SelectInput.propTypes = {
    placeholder: PropTypes.string,
    data: PropTypes.array,
    suffixIcon: PropTypes.element
}

export default SelectInput;