import { Dropdown, Menu, Space, Typography } from 'antd';
import React from 'react';
import { Anchor, Block } from './styles';
import { Themes } from '../constants';
import { Util } from '../../util';


const {Text} = Typography;


const CounterBox =  ({className, type, element, placeCounterBoxRight = true, containerProps, menuIconText, menuRenderer, title, label, icon, titleProps, labelProps, iconProps, menuIcon, menuProps}) => {
    containerProps = {
        background: Themes.white,
        padding: '30px 20px 30px 20px',
        borderRadius: '8px',
        ...containerProps
    };

    titleProps = {
        className: 'counter-box__title',
        ...titleProps
    }

    
    const menu = menuRenderer?? (
        <Menu>
            <Menu.Item key="0">
                <Anchor href="#">1st menu item</Anchor>
            </Menu.Item>
            <Menu.Item key="1">
                <Anchor href="#">2nd menu item</Anchor>
            </Menu.Item>
        </Menu>
    );

    return(
       <Block variant='div' className={`counter-box ${className?? ''}`} {...containerProps}>
            <Space direction='vertical'>
                <Block variant='div' display='flex'>
                    <Block variant='div' flex={1}>
                        {!placeCounterBoxRight?
                            <Space direction='vertical' size={10}>
                                <Text className='counter-box__label py' {...labelProps}>{label}</Text>
                                <Text {...titleProps}>{title}</Text> 
                            </Space> 
                            : 
                            ''
                        }

                        {
                            icon && 
                            <Text className={`counter-box__icon`} style={{backgroundColor: Util.hexToRgba((Themes[type]?? '#000'), 0.1)}} {...iconProps}>
                                {icon}
                            </Text>
                        }
                      
                    </Block>
                    <Block 
                        variant='div' 
                        flex={3} 
                        display='flex'
                    >
                            <Space 
                                direction='horizontal' 
                                align='start'
                                className='flex-right text-right'
                            > 
                                {
                                    element
                                }
                                {placeCounterBoxRight? <Space direction='vertical' size={10}>
                                    <Text className='counter-box__label py' {...labelProps}>{label}</Text>
                                    <Text {...titleProps}>{title}</Text> 
                                </Space> : ''}

                                <Dropdown placement='bottomRight' overlay={menu} trigger={['click']}>
                                    <Anchor display='flex' onClick={e => e.preventDefault()} href='#' className='ant-dropdown-link counter-box__menu' {...menuProps}>
                                        <Space direction='horizontal' size={4}>
                                            {menuIconText} {menuIcon}
                                        </Space>
                                    </Anchor>
                                </Dropdown>
                            </Space>
                    </Block>
                </Block>
            </Space>
       </Block>
    );
}

export default CounterBox;