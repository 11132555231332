import React from "react";
import { List, Skeleton, Typography } from "antd";
import { Themes } from "../constants";
import { Anchor, Block, Heading, VSpace } from "./styles";

const APIProviderStatus = () => {
 
  const data = [
    {
        description: (
          <Anchor href="#">
              <span className={`status-before-content shape-circle success`}></span>
            <Typography.Text
              style={{ color: Themes.secondary, fontWeight: 'bold' }}>
              Airvend airtime service
            </Typography.Text>
            &nbsp; came back at 11am, 26-02-2022
          </Anchor>
        )
    },
    {
        description: (
          <Anchor href="#">
              <span className={`status-before-content shape-circle success`}></span>
            <Typography.Text
              style={{ color: Themes.secondary, fontWeight: 'bold' }}>
              Airvend airtime service
            </Typography.Text>
            &nbsp; came back at 11am, 26-02-2022
          </Anchor>
        )
    },
    {
      description: (
        <Anchor href="#">
            <span className={`status-before-content shape-circle canceled`}></span>
          <Typography.Text
            style={{ color: Themes.secondary, fontWeight: 'bold' }}
           
          >
            Airvend airtime service
          </Typography.Text>
          &nbsp; went down at 11am, 26-02-2022
        </Anchor>
      )
    },
    {
        description: (
          <Anchor href="#">
              <span className={`status-before-content shape-circle canceled`}></span>
            <Typography.Text
              style={{ color: Themes.secondary, fontWeight: 'bold' }}>
              Airvend airtime service
            </Typography.Text>
            &nbsp; went down at 11am, 26-02-2022
          </Anchor>
        )
    },
    {
        description: (
          <Anchor href="#">
              <span className={`status-before-content shape-circle pending`}></span>
            <Typography.Text
              style={{ color: Themes.secondary, fontWeight: 'bold' }}>
              Airvend airtime service
            </Typography.Text>
            &nbsp; went down at 11am, 26-02-2022
          </Anchor>
        )
    }
  ];



  return (
    <Block
      variant="div"
      borderRadius="8px"
      background={Themes.white}
      padding="20px 30px"
      height="529px"
    >
      <Heading color={Themes.secondary} weight={500}>Error Log</Heading>
      <VSpace size='2px' />
      <List
        dataSource={data}
        renderItem={(item, index) =>
          <List.Item key={index}>
            <Skeleton avatar title={false} loading={item.loading} active>
              <List.Item.Meta description={item.description} />
            </Skeleton>
          </List.Item>}
      />
    </Block>
  );
};

export default APIProviderStatus;
