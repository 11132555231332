
import { Paths } from "../../routes/paths";

const table_columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Username',
      dataIndex: 'username',
    },
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      width: 150
    },
    {
      title: 'Role',
      dataIndex: 'role'
    },
    {
      title: 'Access Type',
      dataIndex: 'access_type',
      width: 150
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 100,
      render: (status) => <span className={`status-before-content shape-circle ${status? 'success' : 'canceled'}`}>{status? 'Active':'Inactive'}</span>
    },
    {
      title: 'Added By',
      dataIndex: 'added_by',
      width: 150
    },
    {
      title: 'Total Logins',
      dataIndex: 'total_logins',
      width: 150
    },
    {
      title: 'Date',
      dataIndex: 'date',
      width: 200
    },
  ];
  
const table_data = [
  
];

for (var i = 0; i < 100; i++) {
  let status = (i%2 === 0? true : false);

  table_data.push({
    id: i + 1, 
    name: 'raymond' + i, 
    username: 'raymond' + i, 
    email: 'raymond' + i + '@gmail.com', 
    date: (Math.floor(i/5) + 1 + Math.floor(Math.random() * 10)) + ' Mar 19:30 WAT', 
    access_type: 'Employ', 
    role: 'Default',
    added_by: 'Admin',
    total_logins: 10 * i + 1,
    status
  });
}


const tab_data = [
  {title: 'All User Management', key: Paths.customer}, 
  {title: 'Approved User Management', key: Paths.customer + '/completed'}, 
  {title: 'Canceled User Management', key: Paths.customer + '/pending'}
];

const userData = {
    table: {columns: table_columns, data: table_data},
    tab: tab_data
};

export default userData;



