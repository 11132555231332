
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { AgTable } from '../../common/components';
import { authApi } from '../../state/auth';
import adminData from './data';



const Admin = () => {
    return(
        <Table key='id' />
    );
}

const structuredSelector = createStructuredSelector({
    data: state => state.user,
})
  
const mapDispatchToProps = { loginUser: authApi.loginUser }

export default connect(structuredSelector, mapDispatchToProps)(Admin);


const Table = () => {
    return (
        <AgTable 
            useCheckedSelection={true} 
            preserveSelectedRowKeys={true} 
            rowKey='id' 
            columns={adminData.table.columns} 
            dataSource={adminData.table.data}
        />
    );
}
  