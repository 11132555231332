import { all, call, put, takeLatest } from 'redux-saga/effects';
import serviceApi from './service.api';
import { service } from '../../common/constants';
import { commonActions } from '../common/actions';
import { errorAsyncSaga } from '../common/sagas';


function* asyncFetchServices(action) {
  yield put(commonActions.loading(service.fetchServices));
  try {
    const response = yield call(serviceApi.fetchServices, action.payload.auth, action.payload.pageNumber);
    yield put(commonActions.success(service.fetchServices, response.data));
  }
  catch(err) {
    const errorData = err?.response?.data?? {message: 'An unknown error has occurred!'};
    yield call(errorAsyncSaga, {actionName: service.fetchServices, error: errorData, currentPage: action.payload.pageNumber});
  }
}

export function* watchFetchServicesSaga() {
  yield takeLatest(service.fetchServices, asyncFetchServices);
}

function* asyncFetchServiceDetail(action) {
  yield put(commonActions.loading(service.fetchServiceDetail));
  try {
    const response = yield call(serviceApi.fetchServiceDetail, action.payload.auth, action.payload.accountId);
    yield put(commonActions.success(service.fetchServiceDetail, response.data));
  }
  catch(err) {
    const errorData = err?.response?.data?? {message: 'An unknown error has occurred!'};
    yield call(errorAsyncSaga, {actionName: service.fetchServiceDetail, error: errorData});
  }
}

export function* watchFetchServiceDetailSaga() {
  yield takeLatest(service.fetchServiceDetail, asyncFetchServiceDetail);
}

function* asyncAddNewService(action) {
  yield put(commonActions.loading(service.addNewService));

  try {
    const response = yield call(serviceApi.addNewService, action.payload.auth, action.payload.data);
    yield put(commonActions.success(service.addNewService, response.data));
  }
  catch(err) {
    const errorData = err?.response?.data?? {message: 'An unknown error has occurred!'};
    yield call(errorAsyncSaga, {actionName: service.addNewService, error: errorData});
  }
}

export function* watchAddNewServiceSaga() {
  yield takeLatest(service.addNewService, asyncAddNewService);
}

function* asyncBlockService(action) {
  yield put(commonActions.loading(service.blockService));
  try {
    const response = yield call(serviceApi.blockService, action?.auth, action?.payload);
    yield put(commonActions.success(service.blockService, response.data));
  }
  catch(err) {
    const errorData = err?.response?.data?? {message: 'An unknown error has occurred!'};
    yield call(errorAsyncSaga, {actionName: service.blockService, error: errorData})
  }
}

export function* watchBlockServiceSaga() {
  yield takeLatest(service.blockService, asyncBlockService);
}



export default function* ServiceSagas() {
  yield all([
    watchFetchServicesSaga(),
    watchFetchServiceDetailSaga(),
    watchBlockServiceSaga(),
    watchAddNewServiceSaga()
  ])
}

