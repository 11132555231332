
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { PaymentHistoryTable, AgButton, FundWallet } from '../../common/components';
import { Icons, Themes } from '../../common/constants';

import { Row, Col } from 'react-flexbox-grid';
import { Block } from '../../common/components/styles';

import {
    paymentActions,
    paymentConstants
} from "../../state/payment";

  
const { fetchData } = paymentActions;


const PaymentList = (props) => {
    const [walletFundingVisible, showWalletFunding] = useState(false);

    return(
        <>
            <FundWallet visible={walletFundingVisible} onCancel={() => showWalletFunding(false)} />
            <Row className='mb-1 text-right'>
                <Col xs={12}>
                    <AgButton onClick={() => showWalletFunding(true)} style={{marginRight: '8px'}} primary icon={<Icons.Add />}>Fund Wallet</AgButton>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Block variant='div' background={Themes.white} padding='24px' borderRadius='8px'>
                        <PaymentHistoryTable />
                    </Block>   
                </Col>
            </Row>   
        </>
    );
}

const structuredSelector = createStructuredSelector({
    auth: (state) => state.auth,
    paymentDataFetched: (state) => state?.app[paymentConstants.fetchData] ?? {}
  });
  
const mapDispatchToProps = (dispatch) => {
    return {
      fetchPayments: (state) => dispatch(fetchData(state))
    };
};
  
export default connect(structuredSelector, mapDispatchToProps)(PaymentList);
  
