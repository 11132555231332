import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import ProtectedRoute from './protected_route';

import DashboardLayout from './dashboard_layout';

import { 
  Admin, ApiProvider, Compliance, Customer, Dashboard, ForgotPassword, 
  Login, MyAccount, NoMatch, PasswordResetSuccess, Payment, PaymentDetail, PaymentList, ResetPassword, 
  ServiceListing, Settings, Transaction, TransactionDetail, TransactionList, UserManagement
} from '../../pages';

import { authPrefix, dashboardPrefix, passwordPrefix, Paths, settingsPrefix, userManagementPrefix } from '../../routes/paths';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';


const AppRoutes = (props) => {
    const { auth } = props;
 
    return(
      <Routes>
        <Route path='/' element={<Navigate to={Paths.login} />} />

        <Route path={authPrefix} element={<ProtectedRoute redirectPath={dashboardPrefix} isAllowed={!auth.is_authenticated} />}>
          <Route index element={<Login />} />
          <Route path={Paths.login} element={<Login />} />
          <Route path={passwordPrefix}>
            <Route index element={<ForgotPassword />} />
            <Route path={Paths.forgot_password} element={<ForgotPassword />} />
            <Route path={Paths.reset_password} element={<ResetPassword />} />
            <Route path={Paths.password_reset_success} element={<PasswordResetSuccess />} />
          </Route>
        </Route>

        <Route path={dashboardPrefix} 
          element={
            <ProtectedRoute isAllowed={auth.is_authenticated} >
              <DashboardLayout />
            </ProtectedRoute>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path={Paths.dashboard_overview} element={<Dashboard />} />
          <Route path={Paths.transaction_history} element={<Transaction />}>
            <Route index element={<TransactionList />} />
            <Route path=':transactionId' element={<TransactionDetail />} />
          </Route>
          <Route path={Paths.compliance} element={<Compliance />} />
          <Route path={Paths.services} element={<ServiceListing />} />
          <Route path={Paths.payments} element={<Payment />}>
            <Route index element={<PaymentList />} />
            <Route path=':paymentReference' element={<PaymentDetail />} />
          </Route>
          <Route path={userManagementPrefix} element={<UserManagement />}>
            <Route path={Paths.admins} element={<Admin />}>
              <Route path=':userId' element={() => <h1>User Details</h1>} />
            </Route>
            <Route path={Paths.customers} element={<Customer />} />
          </Route>

          <Route path={settingsPrefix} element={<Settings />}>
            <Route path={Paths.api_providers} element={<ApiProvider />} />
            <Route path={Paths.my_account} element={<MyAccount />} />
          </Route>

        </Route>
        
        <Route path="*" element={<NoMatch />} />
      </Routes>
    );
}

const structuredSelector = createStructuredSelector({
  auth: state => state.auth,
})

const mapDispatchToProps = (dispatch) => {
  return { 
     
  }
}


export default connect(structuredSelector, mapDispatchToProps)(AppRoutes);
