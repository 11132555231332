import { Space } from "antd";
import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { sidebarCollapsedAction } from "../../state/widget";
import { Icons, Themes } from "../constants";
import { Block } from "./styles";

const SidebarCloser = ({ sidebar_collapsed, sidebarCollapsed }) => {
  return (
    <Block
      variant="a"
      display="flex"
      margin="2px"
      className={`sidebar-menu__closer ${sidebar_collapsed &&
        "sidebar-menu__collapsed"}`}
      href="#"
      onClick={() => sidebarCollapsed(!sidebar_collapsed)}
    >
      <Space direction="horizontal">
        <span className="sidebar-menu__closer-icon">
          {sidebar_collapsed
            ? <Icons.MenuUnfold color={Themes.secondary} size={24} />
            : <Icons.MenuFold color={Themes.secondary} size={24} />}
        </span>
      </Space>
    </Block>
  );
};

const structuredSelector = createStructuredSelector({
  auth: state => state.auth,
  sidebar_collapsed: state => state.widget.sidebar_collapsed
});

const mapDispatchToProps = dispatch => {
  return {
    sidebarCollapsed: collapsed => dispatch(sidebarCollapsedAction(collapsed))
  };
};

export default connect(structuredSelector, mapDispatchToProps)(SidebarCloser);
