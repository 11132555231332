import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { authApi } from "../../state/auth";
import { Outlet } from "react-router-dom";

const UserManagement = () => {
  return (
    <Outlet />
  );
};

const structuredSelector = createStructuredSelector({
  data: (state) => state.user
});

const mapDispatchToProps = { loginUser: authApi.loginUser };

export default connect(structuredSelector, mapDispatchToProps)(UserManagement);
