
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { AgFilterDate, AgSearchInput, AgTable } from '../../common/components';
import { Themes } from '../../common/constants';
import { authApi } from '../../state/auth';

import { Row, Col } from 'react-flexbox-grid';
import complianceData from './data';
import { Block } from '../../common/components/styles';


const Compliance = (props) => {
    return(
        <Block variant='div' background={Themes.white} padding='24px' borderRadius='8px'>
                  <Row>
                      <Col x={12} sm={12} md={6}>
                        <Block variant='div' style={{padding: "8px 0px"}}>
                          <span style={{alignSelf: 'center'}} className='wt-600'>Compliance</span>
                        </Block>
                      </Col>
                      <Col x={12} sm={12} md={6} className='text-right'>
                          <Row className='text-right'>
                              <Col md={4} sm={6} mdOffset={4}>
                                  <AgSearchInput placeholder='Search here...' className='search-input'  />
                              </Col>
                              <Col md={4} sm={6}>
                                  <AgFilterDate placeholder='Filter date' className='filter-date' />
                              </Col>
                          </Row>
                      </Col>
                  </Row>
                  <Row className='mt-1'>
                      <Col xs={12}>
                        <Table {...props} />
                      </Col>
                  </Row>
                </Block>   
    );
}

const structuredSelector = createStructuredSelector({
    data: state => state.user,
})
  
const mapDispatchToProps = { loginUser: authApi.loginUser }

export default connect(structuredSelector, mapDispatchToProps)(Compliance);


const Table = () => {
    return (
        <AgTable 
            useCheckedSelection={true} 
            preserveSelectedRowKeys={true} 
            rowKey='id' 
            columns={complianceData.table.columns} 
            dataSource={complianceData.table.data}
        />
    );
}
  