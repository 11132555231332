
import { Paths } from "../../routes/paths";
import { Link } from "react-router-dom";

const table_columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Service',
      dataIndex: 'service',
      render: service => service?.title,
      width: 200
    },
    {
      title: 'Provider',
      dataIndex: 'provider',
      render: provider => provider?.name,
      width: 200
    },
    {
      title: 'Website',
      dataIndex: 'provider',
      render: provider => <Link to={provider?.website}>{provider?.website}</Link>,
      width: 200
    },
    {
      title: 'Status',
      dataIndex: 'is_enabled',
      width: 100,
      render: is_enabled => <span className={`status-before-content shape-circle ${is_enabled? 'success' : 'failed'}`}>{is_enabled? 'Active' : 'Inactive'}</span>
    },
    {
      title: 'Date Added',
      dataIndex: 'created_at',
      width: 200
    },
  ];
  
const table_data = [
  
];

for (var i = 0; i < 100; i++) {
  let status = (i%2 === 0? true : false);

  table_data.push({
    id: i + 1, 
    name: 'raymond' + i, 
    username: 'raymond' + i, 
    email: 'raymond' + i + '@gmail.com', 
    date: (Math.floor(i/5) + 1 + Math.floor(Math.random() * 10)) + ' Mar 19:30 WAT', 
    access_type: 'Employ', 
    role: 'Default',
    added_by: 'Admin',
    total_logins: 10 * i + 1,
    status
  });
}


const tab_data = [
  {title: 'All Customers', key: Paths.customers}, 
  {title: 'Blocked List', key: Paths.customers + '/blocked-list'}, 
];

const serviceData = {
    table: {columns: table_columns, data: table_data},
    tab: tab_data
};

export default serviceData;



