import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { app } from "../../config/app";

const ProtectedRoute = ({ 
  isAllowed=false, 
  redirectPath=app.before_auth_path,
  children
}) =>{

  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />
  }

  return children? children : <Outlet />
}

export default ProtectedRoute;
