import { admin } from '../../common/constants'

const fetchAdmins = (auth, pageNumber) => {
  return {
    type: admin.fetchAdmins,
    payload: {
      auth, pageNumber
    }
  }
}

const addNewAdmin = (auth, data) => {
  return {
    type: admin.addNewAdmin,
    payload: {
      auth, data
    }
  }
}



const approveAction = (auth, id) => {
  return {
    type: admin.approveAsync,
    auth,
    payload: {
      id
    }
  }
}

const declineAction = (auth, id) => {
  return {
    type: admin.declineAsync,
    auth,
    payload: {
      id
    }
  }
}

const uploadDocsAction = (auth, payload) => {
  return {
    type: admin.uploadDocsAsync,
    auth,
    payload
  }
}


const Actions = {
  uploadDocsAction,
  fetchAdmins,
  addNewAdmin,
  approveAction,
  declineAction
}

export default Actions;

