import { service } from '../../common/constants'



const fetchServices = (auth, pageNumber) => {
  return {
    type: service.fetchServices,
    payload: {
      auth, pageNumber
    }
  }
}

const fetchServiceDetail = (auth, accountId) => {
  return {
    type: service.fetchServiceDetail,
    payload: {
      auth, accountId
    }
  }
}


const addNewService = (auth, data) => {
  return {
    type: service.addNewService,
    payload: {
      auth, data
    }
  }
}

const blockService = (auth, data) => {
  return {
    type: service.blockService,
    payload: {
      auth, data
    }
  }
}


const Actions = {
  fetchServices,
  fetchServiceDetail,
  addNewService,
  blockService
}

export default Actions;
