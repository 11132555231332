
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { AddCustomerModal, AgButton, AgTable, NavTab, TableStatSearch } from '../../common/components';
import { Block } from '../../common/components/styles';
import { Icons, Themes } from '../../common/constants';
import { customerActions, customerConstants } from '../../state/customer';
import { Util } from '../../util';
import customerData from './customer-data';



const CustomerListing = ({auth, fetchCustomers, customersFetched}) => {
    const navigate = useNavigate();
    const {search, pathname}= useLocation();
    const [dataSource, setDataSource] = useState([]);

    const [addCustomerModalVisible, showAddCustomerModal] = useState(false);

    const {
        data: customers,
        loading: customersLoading
    } = customersFetched?? {};
    
    useEffect(() => {
      if (!auth.loading && !auth.refresh_token) {
        const page = Util.queryString(search);
        fetchCustomers(auth, page?.q?? 1);
      }
  
    }, [fetchCustomers, auth, search]);
  
    useEffect(() => {
      setDataSource(customers?.data);
    }, [customers?.data]);

    const onTabChanged = (item) => {
        if (item.key) return false //remove later
        navigate(item.key);
    }

    const filterLogic = (searchText, entry) => {
        const item = searchText?.toLowerCase();
      
        let found = entry?.status?.toLowerCase()?.includes(item);
        found = found ? found : entry?.user_data?.full_name?.toLowerCase()?.includes(item);
        found = found ? found : entry?.phone_number?.toLowerCase()?.includes(item);
        found = found ? found : entry?.description?.toLowerCase()?.includes(item);
        found = found ? found : entry?.email?.toLowerCase()?.includes(item);
        found = found ? found : entry?.account_id?.toLowerCase()?.includes(item);
        found = found ? found : entry?.user_type?.type?.toLowerCase()?.includes(item);
        found = found ? found : entry?.status?.toLowerCase()?.includes(item);
        found = found
          ? found
          : entry?.user?.phone_number?.toLowerCase()?.includes(item);
        found = found ? found : entry?.created_at?.toLowerCase()?.includes(item);
  
        return found;
    };
  
    const handleSearch = useCallback(
        (currValue) => {
          setDataSource(Util.filterData(currValue, customers?.data, filterLogic));
        },
        [customers?.data]
    );

    return(
        <>
            <AddCustomerModal visible={addCustomerModalVisible} onCancel={() => showAddCustomerModal(false)} />
            <Row className='mt-1'>
                <Col xs={12} sm={12} md={8}>
                    <NavTab 
                        defaultValue={pathname} 
                        data={customerData.tab}  
                        onClick={onTabChanged}
                        className='nav-tab-container'
                    />
                </Col>
                <Col xs={12} sm={12} md={4} className='text-right'>
                    <AgButton onClick={() => showAddCustomerModal(true)} style={{marginRight: '8px'}} primary icon={<Icons.Add />}>New Customer</AgButton>
                </Col>
            </Row>

            <Block
            variant="div"
            background={Themes.white}
            padding="24px"
            borderRadius="8px"
            >
            <TableStatSearch
                recordTitle="Total Registered Customers"
                recordValue={dataSource?.length?? '___________'}
                handleSearch={handleSearch}
            />
            <Row className="mt-2">
                <Col xs={12}>  
                    <AgTable
                        useCheckedSelection={true}
                        preserveSelectedRowKeys={true}
                        rowKey="id"
                        columns={customerData.table.columns}
                        dataSource={dataSource}
                        loading={customersLoading}
                        pagination={{
                            defaultCurrent: customers?.current_page?? 1,
                            pageSize: customers?.per_page?? 1,
                            total: customers?.total?? 1,
                            onChange: (pageNumber) => {
                                navigate('?q=' + pageNumber);
                            }
                        }}
                    />
                </Col>
            </Row>
        </Block>
        </>
    );
}

const structuredSelector = createStructuredSelector({
    auth: (state) => state.auth,
    customersFetched: (state) => state?.app[customerConstants.fetchCustomers] ?? {}
  });
  
const mapDispatchToProps = (dispatch) => {
    return {
      fetchCustomers: (state, currentPage) => dispatch(customerActions.fetchCustomers(state, currentPage))
    };
};

export default connect(structuredSelector, mapDispatchToProps)(CustomerListing);

