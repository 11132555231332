import { DatePicker } from 'antd';
import React from 'react';
import * as PropTypes from 'prop-types';

import { Icons } from '../constants';


const FilterDate = ({className, placeholder, prefixIcon, suffixIcon, ...rest}) => {
    return (
        <div className={className}>
            {prefixIcon?? <Icons.Filter className='fill' />}
            <DatePicker  
                placeholder={placeholder}
                suffixIcon={suffixIcon?? <Icons.DownArrow className='stroke' />}
                {...rest}
            />
        </div>
    )
}

FilterDate.propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string
}

export default FilterDate;