const compliance = {
    fetchData: 'FETCH_COMPLIANCE_DATA_REQUEST',
    approve: 'APPROVE_COMPLIANCE_REQUEST',
    decline: 'DECLINE_COMPLIANCE_REQUEST',
    approveAsync: 'APPROVE_COMPLIANCE_REQUEST_ASYNC',
    declineAsync: 'DECLINE_COMPLIANCE_REQUEST_ASYNC',
    uploadDocs: 'UPLOAD_COMPLIANCE_DOCS',
    uploadDocsAsync: 'UPLOAD_COMPLIANCE_DOCS_ASYNC',
    downloadDocs: 'DOWNLOAD_COMPLIANCE_DOCS',
    success: 'COMPLIANCE_REQUEST_SUCCESS',
    error: 'COMPLIANCE_REQUEST_ERROR',
    loading: 'COMPLIANCE_REQUEST_LOADING'
};

export default compliance;