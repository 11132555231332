import React from 'react';
import * as PropTypes from 'prop-types';


const ButtonInput = ({className, onClick, children, icon, success, primary, warning, danger, ...rest}) => {

    const successClass = success? 'btn-success' : '';
    const primaryClass = primary? 'btn-primary' : '';
    const warningClass = warning? 'btn-warning' : '';
    const dangerClass = danger? 'btn-danger' : '';

    const mergedClassNames = 'btn ' + className + ' ' + successClass + ' ' + primaryClass
    + ' ' + warningClass + ' ' + dangerClass;

    return (
        <button onClick={onClick} className={mergedClassNames} {...rest} >
            {icon}&nbsp;&nbsp;{children}
        </button>
    )
}

ButtonInput.propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string
}

export default ButtonInput;