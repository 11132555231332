import { Breadcrumb } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { Util } from '../../util';


const AgBreadcrumb = ({path, dashboardPrefix = '/'}) => {
    const items = path?.split('/');
    return(
        <Breadcrumb>
            {
                items?.map((item, index) => (
                    item.length > 0 && <Breadcrumb.Item key={item}>
                        {
                            index < items.length - 1?
                            <Link to={index === 1? dashboardPrefix : item}>{Util.titleCase(item.replace('-', ' '))}</Link>
                            :
                            <span>{Util.titleCase(item.replace('-', ' '))}</span>
                        }
                    </Breadcrumb.Item>
                ))
            }
        </Breadcrumb>
    );
}

export default AgBreadcrumb;