import React, { useEffect } from "react";
import { Col, Row } from "react-flexbox-grid";
import { Line } from "@ant-design/plots";


import { Themes } from "../constants";
import CounterBox from "./counter_box";
import { Block } from "./styles";

import config from '../../pages/dashboard/data';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { dashboardActions, dashboardConstants } from "../../state/dashboard";
import DateRangePicker from './date-range-picker';
import { Util } from "../../util";


const { fetchGraphData } = dashboardActions;

const DashboardChart = ({auth, fetchChartData, dataFetched, chartDataFetched}) => {
  const { data: dashData } = dataFetched;
  const { data: chartData } = chartDataFetched;
  const { chart_config: chartConfig } = config;

  chartConfig.data = chartData?? [];
 
  useEffect(() => {
    if (!auth.loading && !auth.refresh_token)
      fetchChartData(auth, {startDate: '2021-05-1', endDate: '2022-08-14'});
  }, [fetchChartData, auth]);


  return (
    <Block
      variant="div"
      background={Util.hexToRgba(Themes.white)}
      style={{ borderRadius: "8px" }}
    >
      <Row>
        <Col xs={12}>
          <CounterBox
            type="success"
            title="Total Revenue"
            label={Util.formatAmount(dashData?.total_revenue, dashData?.currency)}
            placeCounterBoxRight={false}
  
            element={
              <DateRangePicker />
            }
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <Block variant="div" padding="0px 20px 30px 20px" background="rgba(0, 0, 0, 0.01)">
            <Line {...chartConfig} color={Themes.secondary} />
          </Block>
        </Col>
      </Row>
    </Block>
  );
};

const structuredSelector = createStructuredSelector({
  auth: (state) => state.auth,
  dataFetched: (state) => state?.app[dashboardConstants.fetchData]?? {},
  chartDataFetched: (state) => state?.app[dashboardConstants.fetchGraphData]?? {}
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchChartData: (state, data) => dispatch(fetchGraphData(state, data))
  };
};

export default connect(structuredSelector, mapDispatchToProps)(DashboardChart);
