
import { connect } from '../../api/connect';

const DashboardApi = {
    fetchData: async (authState) => {
        const response = await connect(authState).get('admin/statistics');
        return response;
    },
    fetchGraphData: async (authState, data) => {
        const { startDate, endDate } = data;
        const endpoint = `admin/statistics/sales/${startDate}/${endDate}`;
        const response = await connect(authState).get(endpoint);
        return response;
    },
}
  
export default DashboardApi;