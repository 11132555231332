
import React from 'react';
import { Menu } from 'antd';

const NavMenu = ({children, ...rest}) => {
  return (
    <Menu {...rest}>{children}</Menu>
  );
}

NavMenu.Item = ({children, ...rest}) => {
  return(
    <Menu.Item {...rest}>{children}</Menu.Item>
  );
}

NavMenu.SubMenu = ({children, ...rest}) => {
  return(
    <Menu.SubMenu {...rest}>{children}</Menu.SubMenu>
  );
}

NavMenu.ItemGroup = ({children, ...rest}) => {
  return(
    <Menu.ItemGroup {...rest}>{children}</Menu.ItemGroup>
  );
}

const NavTab = ({defaultValue, containerProps, data, ...rest}) => {
  return(
    <NavMenu selectedKeys={defaultValue} className='nav-tab-container' mode='horizontal' {...rest}>
      {
        data?.map(item => <NavMenu.Item key={item.key} icon={item?.icon}>{item.title}</NavMenu.Item>)
      }
    </NavMenu>
  );
}

NavTab.NavMenu = NavMenu;

export default NavTab;

