import { Constants } from "./constants"



const success = (actionName, response) => {
  const data = response?.data?? {};

  let pagination = {
    currentPage: data?.current_page,
    pageSize: data?.per_page?? (data?.length?? 1),
    totalPages: data?.total_pages?? 1
  };

  return {
    type: Constants.success,
    payload: {
      [actionName]: {
        loading: false,
        status: 'success',
        message: response?.message,
        data: response?.data,
        ...pagination
      }

    }
  }
}

const error = (actionName, response, currentPage = 1) => {
  return {
    type: Constants.error,
    actionName,
    payload: {
      [actionName]: {
        loading: false,
        status: 'error',
        message: response?.message,
        error: response?.error,
        currentPage
      }

    }
  }
}

const loading = (actionName) => {
  return {
    type: Constants.loading,
    actionName,
    payload: {
      [actionName]: {
        loading: true
      }
    }
  }
}

export const commonActions = {
  success, error, loading
}
