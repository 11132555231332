import React from "react";
import { useNavigate } from "react-router-dom";
import { PublicLayout } from "../../common/components";
import {
  Heading,
  VSpace,
  Button
} from "../../common/components/styles";

import { Paths } from "../../routes/paths";

const ResetPasswordSuccess = () => {
  const navigate = useNavigate();

  const handleContinueToLogin = () => {
    navigate(Paths.login);
  }

  return (
    <PublicLayout>
      <Heading size="20px" weight={600} className='text-center'>
        Your password has been reset!
      </Heading>
      <VSpace space="16px" />

     <center>
      <Button primary onClick={handleContinueToLogin} cursor='true'>Continue to Login</Button>
     </center>
    </PublicLayout>
  );
};

export default ResetPasswordSuccess;
