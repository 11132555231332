import { all } from "redux-saga/effects";
import { adminSagas } from "./admin";
import { authSagas } from "./auth";
import { complianceSagas } from "./compliance";
import { customerSagas } from "./customer";
import { dashboardSagas } from "./dashboard";
import { paymentSagas } from "./payment";
import { serviceSagas } from "./service";
import { transactionSagas } from "./transaction";


export default function* rootSaga() {
    yield all([
      authSagas(),
      dashboardSagas(),
      complianceSagas(),
      transactionSagas(),
      adminSagas(),
      customerSagas(),
      paymentSagas(),
      serviceSagas()
    ])
}
  