import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { useFormik } from 'formik';
import * as Yup from 'yup';


import {
  Input,
  FormGroup,
  Anchor,
  Button,
  Block
} from "../../common/components/styles";
import { Themes, Icons } from "../../common/constants";
import Checkbox from "react-custom-checkbox";

import { loginRequestAction, resetLoginAction } from "../../state/auth";
import { Loading } from "../../common/components";
import { Paths } from "../../routes/paths";
import { message as alertMsg } from "antd";
import { useEffect } from "react";

import { VSpace } from './styles';


const validationSchema = Yup.object({
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required')
});

const initialValues = {
  username: '',
  password: ''
}

const Login = props => {
  const {auth, resetLogin} = props;
  const { loading, message, error, is_authenticated } = auth?? {};

  useEffect(() => {
    if (error) {
      alertMsg.error(message, 5);
    }
    else {
      setTimeout(() => {
        if (!is_authenticated)
          resetLogin();
        }, 5000)
    }
    
  }, [error, message, resetLogin, is_authenticated]);

  const handleLoginRequest = values => {
    let username = values.username;
    let password = values.password;

    props.loginRequest({
      country: 'NG',
      login_id: username,
      password: password
    });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleLoginRequest
  })

  const handleChange = (e) => {
    formik.handleChange(e);
  }

  const handleBlur = (e) => {
    formik.handleBlur(e);
  }

  return (
    <form onSubmit={formik.handleSubmit}>
        <Loading background={'transparent'} loading={loading} showLogo={false} iconColor={Themes.secondary} />
        <FormGroup direction="column" margin="1rem 0rem">
          <Input
            inputProps={{
              name: "username",
              id: "username",
              placeholder: "Username",
              onChange: handleChange,
              onBlur: handleBlur,
              value: formik.values.username
            }}
          />
          {
            formik.touched.username 
            && formik.errors.username 
            && <span className='text-danger'>{formik.errors.username}</span>
          }
        </FormGroup>

        <FormGroup direction="column" margin="1rem 0rem">
          <Input
            inputProps={{
              type: "password",
              name: "password",
              id: "password",
              placeholder: "Password",
              onChange: handleChange,
              onBlur: handleBlur,
              value: formik.values.password
            }}
          />

          {
            formik.touched.username 
            && formik.errors.password 
            && <span className='text-danger'>{formik.errors.password}</span>
          }
        </FormGroup>

        <FormGroup margin="1rem 0rem">
          <Checkbox
            borderColor={Themes.dark}
            size={20}
            icon={<Icons.Check color={Themes.dark} size={14} />}
            labelStyle={{
              fontWeight: "500",
              marginLeft: "12px",
              color: Themes.dark
            }}
            label="Remember me"
          />

        </FormGroup>

        <FormGroup className="mt-2">
          <Button type="submit" cursor="true" primary fullWidth>
            {loading ? 'Authenticating...' : "Login"}
          </Button>
        </FormGroup>

        <VSpace size='20px' />
        <Block variant='div' className='text-center'>
          <Anchor
              className="wt-500"
              color={Themes.primary}
              href={Paths.forgot_password}
              display='inline'
            >
              Forgot Password?
          </Anchor>
        </Block>
      </form>
  );
};

const structuredSelector = createStructuredSelector({
  auth: state => state.auth
});

const mapDispatchToProps = dispatch => {
  return {
    loginRequest: credentials => dispatch(loginRequestAction(credentials)),
    resetLogin: () => dispatch(resetLoginAction()),
  };
};

export default connect(structuredSelector, mapDispatchToProps)(Login);
