import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  Input,
  Heading,
  VSpace,
  FormGroup,
  Anchor,
  Button,
  Block
} from "../../common/components/styles";
import { Themes, Icons } from "../../common/constants";
import Checkbox from "react-custom-checkbox";

import { PublicLayout } from "../../common/components";

import { Paths } from "../../routes/paths";
import { Form, message } from "antd";
import { useNavigate } from "react-router-dom";

const CreateAccount = () => {
  const navigate = useNavigate();

  const handleSubmit = () => {
    message.success('Account created successfully. You will be redirected shortly.', null, () => {
      navigate(Paths.login);
    });
  };

  return (
    <PublicLayout>
      <Heading className="text-center" size="24px" weight={600}>
        Create an Account
      </Heading>
      <VSpace space="10px" />

      <Heading className="text-center" type="h2">
        Create an Account to Continue
      </Heading>
      <VSpace space="24px" />
      <Form onFinish={handleSubmit}>
        <FormGroup direction="column" margin="1rem 0rem">
          <Input
            labelProps={{
              text: "Email/Phone Number",
              htmlFor: "email_or_phone"
            }}
            inputProps={{
              name: "email_or_phone",
              id: "email_or_phone",
              placeholder: ""
            }}
          />
        </FormGroup>

        <FormGroup direction="column" margin="1rem 0rem">
          <Input
            labelProps={{
              text: "Username",
              htmlFor: "username"
            }}
            inputProps={{
              name: "username",
              id: "username",
              placeholder: ""
            }}
          />
        </FormGroup>

        <FormGroup direction="column" margin="1rem 0rem">
          <Input
            labelProps={{
              text: "Password",
              htmlFor: "password"
            }}
            inputProps={{
              type: "password",
              name: "password",
              id: "password",
              placeholder: ""
            }}
          />
        </FormGroup>

        <Block variant="div" display="flex" margin="1rem 0rem">
          <Checkbox
            borderColor={Themes.dark}
            size={20}
            icon={<Icons.Check color={Themes.dark} size={14} />}
            labelStyle={{
              fontWeight: "600",
              marginLeft: "12px"
            }}
            label=""
          />
          &nbsp;&nbsp;&nbsp;I agree to the &nbsp;<Anchor
            href="#"
            className="wt-normal"
            target="_BLANK"
          >
            Terms of Service
          </Anchor>&nbsp;and&nbsp;<Anchor
            href="#"
            className="wt-normal"
            target="_BLANK"
          >
            Policy
          </Anchor>
        </Block>

        <FormGroup className="mt-2">
          <Button fullWidth secondary cursor="true" type="submit">
            Create an Account
          </Button>
        </FormGroup>

        <Block variant="div" className="text-center mt-2">
          <span className="wt-normal">Already has an account?</span>{" "}
          <Anchor href={Paths.login}>Login</Anchor>
        </Block>
      </Form>
    </PublicLayout>
  );
};

const structuredSelector = createStructuredSelector({
  auth: state => state.auth
});

const mapDispatchToProps = {  };

export default connect(structuredSelector, mapDispatchToProps)(CreateAccount);
