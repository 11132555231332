import { Link } from "react-router-dom";
import { Icons, Themes } from "../../common/constants";
import { Paths } from "../../routes/paths";

const table_columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id > b.id
    },
    {
      title: 'Username',
      dataIndex: 'username'
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: (a, b) => a.status > b.status,
      render: (status) => <span className={`status-before-content shape-circle ${status.toLowerCase()}`}>{status}</span>
    },
    {
      title: 'Approved By',
      dataIndex: 'approved_by'
    },
    {
      title: 'Comment',
      dataIndex: 'comment'
    },
    {
      title: '',
      dataIndex: 'marked_pan',
      render: _ => <Link to="#" style={{display: 'flex', justifyContent: 'flex-end'}}><Icons.CounterMenu color={Themes.gray_100} /></Link>
    }
  ];
  
const table_data = [
  
];

for (var i = 0; i < 100; i++) {
  let status = (i%2 === 0? 'Success' : (i%3 === 0? 'Pending' : 'Canceled'));

  table_data.push({
    id: i + 1, 
    email: 'test' + i + '@gmail.com', 
    username: 'test' + i, 
    comment: '₦500,000.00', 
    approved_by: 'Joel 080xxxxxxxxx' + (i + 1),
    status
  });
}


const tab_data = [
  {title: 'All Transactions', key: Paths.compliance}, 
  {title: 'Completed', key: Paths.compliance + '/completed'}, 
  {title: 'Pending', key: Paths.compliance + '/pending'}, 
  {title: 'Canceled', key: Paths.compliance + '/canceled'}
];

const complianceData = {
    table: {columns: table_columns, data: table_data},
    tab: tab_data
};

export default complianceData;



