import React, { useEffect } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import InfoContainer from './info-container';
import Info from './info';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { customerActions, customerConstants } from "../../../state/customer";
import ButtonInput from '../button';
import { Util } from '../../../util';


const { fetchCustomerDetail } = customerActions;


const CustomerInfo = ({cols = 4, auth, customerDataFetched, fetchCustomerDetail, accountId}) => {
    const { loading, data: customerData } = customerDataFetched;
    
    useEffect(() => {
        if (!auth.loading && !auth.refresh_token)
            fetchCustomerDetail(auth, {accountId});
    }, [fetchCustomerDetail, auth, accountId]);

    return(
        <Grid>
            <InfoContainer loading={loading} className='mb-2'
                header={
                    <Row className='mb-1'>
                        <Col md={6}>
                            <h1 className='h1'>
                                {(customerData?.user_data?.full_name?? 'Customer Info')}
                                (<span className={'text-' + customerData?.verification?.kyc_status?.toLowerCase()}>
                                    {Util.capitalize(customerData?.verification?.kyc_status)}
                                </span>)
                            </h1>
                            <h2 className='h2'>
                                Account created on <span className='wt-600'>{customerData?.created_at}</span>
                            </h2>
                        </Col>
                        
                        <Col md={6} className='flex flex-middle flex-right py-1'>
                            <ButtonInput danger>Block</ButtonInput>
                        </Col>
                    </Row>   
                }
            >
                <Info xs={4} md={12/cols} title={'Customer Name'} description={customerData?.usr_data?.full_name?? 'N/A'} />
                <Info xs={4} md={12/cols} title={'Email Address'} description={customerData?.email} />
                <Info xs={4} md={12/cols} title={'Mobile Number'} description={customerData?.phone_number} />
                <Info xs={4} md={12/cols} title={'Account ID'} description={customerData?.account_id} />
              
            </InfoContainer>
        </Grid>
    );
}



const structuredSelector = createStructuredSelector({
    auth: (state) => state.auth,
    customerDataFetched: (state) => state?.app[customerConstants.fetchCustomerDetail]?? {},
  });
  
  const mapDispatchToProps = (dispatch) => {
    return {
      fetchCustomerDetail: (state, data) => dispatch(fetchCustomerDetail(state, data))
    };
  };
  
  export default connect(structuredSelector, mapDispatchToProps)(CustomerInfo);