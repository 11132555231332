
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { AgTable } from '../../common/components';
import { authApi } from '../../state/auth';
import customerData from './data';



const Customer = () => {
    return(
        <Table key='id' />
    );
}

const structuredSelector = createStructuredSelector({
    data: state => state.user,
})
  
const mapDispatchToProps = { loginUser: authApi.loginUser }

export default connect(structuredSelector, mapDispatchToProps)(Customer);


const Table = () => {
    return (
        <AgTable 
            useCheckedSelection={true} 
            preserveSelectedRowKeys={true} 
            rowKey='id' 
            columns={customerData.table.columns} 
            dataSource={customerData.table.data}
        />
    );
}
  