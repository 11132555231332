
import { Link } from "react-router-dom";
import { Icons, Themes } from "../../common/constants";
import { Paths } from "../../routes/paths";
import { Util } from "../../util";

const table_columns = [
    {
      title: 'Transaction ID',
      dataIndex: 'transaction_id',
      sorter: (a, b) => a.transaction_id > b.transaction_id
    },
    {
      title: 'Type',
      dataIndex: 'service',
      render: service => service?.title,
      width: '200px'
    },
    {
      title: 'Customer',
      dataIndex: 'user',
      sorter: (a, b) => a.user.email > b.user.email,
      render: user => <div>
      <p><span className='h-label'>Email Address</span> <span className='h-value'>{user?.email}</span></p>
      <p><span className='h-label'>Phone Number</span> <span className='h-value'>{user?.phone_number}</span></p>
    </div>
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      sorter: (a, b) => a.created_at > b.created_at
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      sorter: (a, b) => a.amount > b.amount,
      render: (a, record, index) => <div>
        <p><span className='h-label'>Amount Paid</span> <span className='h-value'>{Util.formatAmount(record?.debited_amount?? 0, record?.currency_symbol, false)}</span></p>
        <p><span className='h-label'>Charge</span> <span className='h-value'>{Util.formatAmount(record?.charge?? 0, record?.currency_symbol, false)}</span></p>
        <p><span className='h-label'>Curr bal</span> <span className='h-value'>{Util.formatAmount(record?.post_balance?? 0, record?.currency_symbol, false)}</span></p>
        <p><span className='h-label'>Pre bal</span> <span className='h-value'>{Util.formatAmount(record?.pre_balance?? 0, record?.currency_symbol, false)}</span></p>
      </div>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: status => <span className={`status-before-content shape-circle ${status?.toLowerCase()}`}>{Util.capitalize(status)}</span>
    },
    {
      title: '',
      dataIndex: 'transaction_id',
      render: (transaction_id, record, index) => <Link className="text-right" 
        to={Paths.transaction_history + '/' + transaction_id?.replace('#','')}
        state={{
          transactionId: record?.transaction_id,
          accountId: record?.user?.account_id
        }}
      ><Icons.Eye size={24} color={Themes.gray} /></Link>
    }
  ];
  
const table_data = [
  
];

for (var i = 0; i < 100; i++) {
  let status = (i%2 === 0? 'Success' : (i%3 === 0? 'Pending' : 'Canceled'));

  table_data.push({
    id: i + 1, 
    transaction_id: '#12345465345' + i, 
    username: 'raymond' + i, 
    date: (Math.floor(i/5) + 1 + Math.floor(Math.random() * 10)) + ' Mar 19:30 WAT', 
    amount_paid: '₦500,000.00', 
    customer_info: 'Jennifer Mfong 0907686754' + (i + 1),
    status: <span className={`status-before-content shape-circle ${status.toLowerCase()}`}>{status}</span>,
  });
}


const tab_data = [
  {title: 'All Transactions', key: Paths.transaction_history}, 
  //{title: 'Bill Payments', key: Paths.transaction_history + '/bill-payments'}, 
  //{title: 'Bank Transfers', key: Paths.transaction_history + '/bank-transfers'}, 
];

const transactionData = {
    table: {columns: table_columns, data: table_data},
    tab: tab_data
};

export default transactionData;



