import { Auth } from '../../common/constants'

export const loginSuccess = (response) => {
  return {
    type: Auth.loginSuccess,
    payload: response
  };
}

export const loginRequestAction = (credentials) => {
  return {
    type: Auth.loginRequest,
    payload: credentials
  }
}


export const refreshTokenAction = () => {
  return {
    type: Auth.refreshToken
  }
}

export const resetLoginAction = () => {
  return {
    type: Auth.resetLogin
  }
}

export const loginError = (error) => {
  return {
    type: Auth.loginError,
    payload: error
  }
}

export const logoutSuccess = () => {
  return {
    type: Auth.logoutSuccess
  };
}

export const logoutRequestAction = (payload) => {
  return {
    type: Auth.logoutRequest,
    payload
  }
}

export const logoutError = (error) => {
  return {
    type: Auth.logoutError,
    payload: error
  }
}

