import { compliance } from '../../common/constants'

const fetchData = (auth, pageNumber) => {
  return {
    type: compliance.fetchData,
    payload: {
      auth, pageNumber
    }
  }
}


const approveAction = (auth, id) => {
  return {
    type: compliance.approveAsync,
    auth,
    payload: {
      id
    }
  }
}

const declineAction = (auth, id) => {
  return {
    type: compliance.declineAsync,
    auth,
    payload: {
      id
    }
  }
}

const uploadDocsAction = (auth, payload) => {
  return {
    type: compliance.uploadDocsAsync,
    auth,
    payload
  }
}

const downloadDocsAction = (auth, payload) => {
  return {
    type: compliance.downloadDocsAction,
    auth, payload
  }
}


const Actions = {
  uploadDocsAction,
  downloadDocsAction,
  fetchData,
  approveAction,
  declineAction
}

export default Actions;

