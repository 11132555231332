import React, { useCallback, useEffect, useState } from "react";
import AgTable from "./custom_table";
import { Themes } from "../constants";
import { Block } from "./styles";
import transactionData from "../../pages/transaction/data";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  transactionActions,
  transactionConstants
} from "../../state/transaction";
import { useLocation, useNavigate } from "react-router-dom";
import { Util } from "../../util";
import TableStatSearch from "./table-stat-search";

const { fetchData } = transactionActions;

const TransactionHistoryTable = ({
  auth,
  fetchTransactions,
  txnDataFetched
}) => {
  const {
    data: txnData,
    loading: dataFetchedLoading
  } = txnDataFetched;

  const navigate = useNavigate();
  const {search}= useLocation();
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    if (!auth.loading && !auth.refresh_token) {
      const page = Util.queryString(search);
      fetchTransactions(auth, page?.q?? 1);
    }

  }, [fetchTransactions, auth, search]);

  useEffect(() => {
    setDataSource(txnData?.data);
  }, [txnData?.data]);

  const filterLogic = (searchText, entry) => {
      const item = searchText?.toLowerCase();
    
      let found = entry?.status?.toLowerCase()?.includes(item);
      found = found ? found : entry?.user?.user_data?.full_name?.toLowerCase()?.includes(item);
      found = found ? found : entry?.description?.toLowerCase()?.includes(item);
      found = found ? found : entry?.user?.email?.toLowerCase()?.includes(item);
      found = found ? found : entry?.service?.title?.toLowerCase()?.includes(item);
      found = found ? found : entry?.transaction_id?.toLowerCase()?.includes(item);
      found = found ? found : entry?.status?.toLowerCase()?.includes(item);
      found = found
        ? found
        : entry?.user?.phone_number?.toLowerCase()?.includes(item);
      found = found ? found : entry?.created_at?.toLowerCase()?.includes(item);

      return found;
  };

  const handleSearch = useCallback(
      (currValue) => {
        setDataSource(Util.filterData(currValue, txnData?.data, filterLogic));
      },
      [txnData?.data]
  );

  return (
    <Block
      variant="div"
      borderRadius="8px"
      background={Themes.white}
      padding="20px 10px"
    >
      <TableStatSearch 
        recordTitle = "Total Transactions" 
        recordValue={txnData?.total + ' records'} 
        handleSearch={handleSearch}
      />
      <AgTable
        useCheckedSelection={true}
        preserveSelectedRowKeys={true}
        rowKey="id"
        columns={transactionData.table.columns}
        dataSource={dataSource}
        loading={dataFetchedLoading}
        pagination={{
          defaultCurrent: txnData?.current_page?? 1,
          pageSize: txnData?.per_page?? 1,
          total: txnData?.total?? 1,
          onChange: (pageNumber) => {
            navigate('?q=' + pageNumber);
          }
        }}
      />
    </Block>
  );
};

const structuredSelector = createStructuredSelector({
  auth: (state) => state.auth,
  txnDataFetched: (state) => state?.app[transactionConstants.fetchData] ?? {}
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTransactions: (state, currentPage) => dispatch(fetchData(state, currentPage))
  };
};

export default connect(structuredSelector, mapDispatchToProps)(TransactionHistoryTable);
