
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { AgSearchInput, AgTable, DashboardLayout } from '../../common/components';
import { Icons, Themes } from '../../common/constants';
import { authApi } from '../../state/auth';

import { Grid, Row, Col } from 'react-flexbox-grid';
import accountData from './data';
import { Block } from '../../common/components/styles';


const Accounting = (props) => {

    return(
        <DashboardLayout pageTitle='Accounting' pageIcon={Icons.Accounting} >
            <Grid className='mt-2'> 
                <Row>
                    <Col md={8}>
                        <Row>
                            <Col md={2}>
                                <Block 
                                    variant='div' 
                                    style={{
                                        padding: "8px 0px"
                                    }}
                                >
                                    <span style={{alignSelf: 'center'}} className='wt-600'>All Users</span>&nbsp;| (127)
                                </Block>
                            </Col>
                            <Col md={4}>
                                <AgSearchInput placeholder='Search here...'  className='search-input mb-1' />
                            </Col>  
                        </Row>
                    </Col> 
                </Row>

               <Row className='mt-1'>
                    <Col xs={12}>
                        <Block variant='div' background={Themes.white} padding='24px' borderRadius='8px'>
                            <Table {...props} />
                        </Block>  
                    </Col>
                </Row>
                     
            </Grid>
        </DashboardLayout>
    );
}

const structuredSelector = createStructuredSelector({
    data: state => state.user,
})
  
const mapDispatchToProps = { loginUser: authApi.loginUser }

export default connect(structuredSelector, mapDispatchToProps)(Accounting);


const Table = () => {
    return (
        <AgTable 
            useCheckedSelection={true} 
            preserveSelectedRowKeys={true} 
            rowKey='id' 
            columns={accountData.table.columns} 
            dataSource={accountData.table.data}
        />
    );
}
  