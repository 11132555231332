
import { Auth } from '../../common/constants';

const initialState = {
    loading: false,
    user: null,
    access_token: null,
    token_type: null,
    is_authenticated: false,
    refresh_token: false,
    error: null,
    message: null
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case Auth.loginRequest:
            return {...state, loading: true};
        case Auth.loginSuccess:
            const {data, message, status } = action.payload;
            return {
                ...state, 
                error: null, 
                loading: false, 
                refresh_token: false,
                user: data.user, 
                access_token: data.access_token, 
                token_type: data.token_type, 
                is_authenticated: status?.toLowerCase() === 'success', 
                message
            };
        case Auth.loginError:
            return {...state, loading: false, is_authenticated: state?.refresh_token?? false, error: action.payload, message: action.payload.message};
        case Auth.logoutRequest:
            return {...state, loading: true};
        case Auth.logoutSuccess:
            return initialState;
        case Auth.logoutError:
            return {...state, loading: false, error: action.payload}; 
        case Auth.refreshToken:
            return {
                ...state,
                refresh_token: true,
                loading: false
            }
        case Auth.resetLogin:
            return {
                ...state, 
                is_authenticated: state?.refresh_token,
                loading: false,
                user: state?.refresh_token? state?.user : null
            };
        default:
            return state
    }
}

export default authReducer;