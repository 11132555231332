import { Button, Form, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { Heading, VSpace } from "./styles";

import {
  paymentActions,
  paymentConstants
} from "../../state/payment";


const { fundWallet } = paymentActions;

const FundWallet = ({ wallet_funding, fundWallet, auth, visible, onCancel }) => {
  const [fundingStarted, startFunding] = useState(false);

  const onFinish = (values) => {
    fundWallet(auth, values);
    setTimeout(() => {
      startFunding(true);
    }, 3000);
  };

  const { error, loading, status, message } = wallet_funding;

  useEffect(() => {
    if (!loading && fundingStarted && status?.toLowerCase() === 'success') {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }, [loading, status, message, fundingStarted]);

  return (
    <Modal title="Fund Wallet" visible={visible} footer={null} closable={true} onCancel={onCancel}>
      <Form
        layout="vertical"
        name="wallet_funding"
        initialValues={{
          remember: true
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        {status === "error" && !loading && <Heading className='text-danger'>{error?.message}</Heading>}
        {status === "success" && fundingStarted && <Heading className='text-success'>{message}. Updating payment history in 2secs...</Heading>}
        <VSpace />
        <Form.Item
          label="Account ID/Mobile Number/Customer Email"
          name="account_id"
          rules={[
            {
              required: true,
              message: "Account ID, Mobile Number or Customer Email is required"
            }
          ]}
        >
          <Input />
        </Form.Item>
        <VSpace space="2px" />
        <Form.Item
          label="Topup Amount (NGN)"
          name="amount"
          rules={[
            {
              required: true,
              message: "Amount is required"
            }
          ]}
        >
          <Input />
        </Form.Item>
        <VSpace space="2px" />
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: false
            }
          ]}
        >
          <Input.TextArea />
        </Form.Item>
        <VSpace />
        <Form.Item
        >
          <Button loading={loading} type="primary" htmlType="submit">
            Top Up
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};


const structuredSelector = createStructuredSelector({
  auth: state => state.auth,
  wallet_funding: state => state?.app[paymentConstants.fundWallet] ?? {}
});

const mapDispatchToProps = dispatch => {
  return {
    fundWallet: (state, payload) => dispatch(fundWallet(state, payload))
  };
};

export default connect(structuredSelector, mapDispatchToProps)(FundWallet);