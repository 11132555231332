import React from "react";

import {
  Heading,
  VSpace,
 
} from "../../common/components/styles";
import { Themes } from "../../common/constants";

import { LoginForm, PublicLayout } from "../../common/components";

const Login = () => {
  return (
    <PublicLayout>
      <Heading color={Themes.secondary} className='text-center' size="24px">
        Welcome Back, Admin!
      </Heading>
      <Heading 
        color={Themes.gray} 
        weight={500} 
        size="14px"
        linespacing={2}
        className='text-center'
      >
        Please login to access your dashboard
      </Heading>
      
      <VSpace space="24px" />
      <LoginForm />
    </PublicLayout>
  );
};

export default Login;
