
import { connect } from '../../api/connect';

const transactionApi = {
    fetchTransactionData: async (authState, pageNumber) => {
        const params = pageNumber? `page=${pageNumber}` : '';
        const response = await connect(authState).get(`admin/transactions?${params}`);
        return response;
    },
    fetchTransactionDetail: async (authState, data) => {
        const { txRef } = data;
        const response = await connect(authState).get(`admin/transactions/${txRef}`);
        return response;
    },
    cancelTransaction: async(authState, id) => {
        const response = await connect(authState).post(`v1/process_merchant_transaction/${id}`, {
            status: 'Approved',
            reason: 'N/A'
        });
        return response;
    },
    downloadReport: async(authState, id) => {
        const response = await connect(authState).post(`v1/process_merchant_transaction/${id}`, {
            status: 'Declined',
            reason: 'N/A'
        });
        return response;
    }
}
  
export default transactionApi;