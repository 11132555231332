const auth = {
    loginRequest: 'LOGIN_REQUEST',
    loginSuccess: 'LOGIN_SUCCESS',
    resetLogin: 'RESET_LOGIN',
    loginError: 'LOGIN_ERROR',
    logoutRequest: 'LOGOUT_REQUEST',
    logoutSuccess: 'LOGOUT_SUCCESS',
    logoutError: 'LOGOUT_ERROR',
    sessionExpired: 'Your session has expired. Please login again',
    refreshToken: 'REFRESH_TOKEN'
};

export default auth;