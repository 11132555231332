import React from 'react';
import { app } from '../../config/app';
import { Images, Themes } from '../constants';
import { Block } from './styles';
import Spinner from './spinner';


const Loading  = ({background=Themes.secondary, showLogo=true, loading=false, useLoadingIcon=true, iconSize=30, iconColor = '#eeeeee'}) => {
    return (
        loading? 
        
        <Block 
            variant='div' 
            display='flex'
            justifycontent='center'
            alignitems='center'
            direction='column'
            background={background}
            style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: '0px',
                left: '0px',
                
            }}
        >

            {useLoadingIcon && 
            <>
                <Block variant='div' className='mb-2'>
                    <Spinner color={iconColor} loading={loading} size={iconSize} />
                </Block>
            </>
            }

            {showLogo && <img src={Images.logo} alt={app.company.name} width='140px' />}

        </Block>
        :
        <></>    
    );
}

export default Loading;