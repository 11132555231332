import { payment } from '../../common/constants'



const fetchData = (auth, pageNumber) => {
  return {
    type: payment.fetchData,
    payload: {
      auth, pageNumber
    }
  }
}

const fetchPaymentDetail = (auth, txRef) => {
  return {
    type: payment.fetchPaymentDetail,
    payload: {
      auth, txRef
    }
  }
}

const fundWallet = (auth, data) => {
  return {
    type: payment.fundWallet,
    payload: {
      auth, data
    }
  }
}


const printReport = (auth, payload) => {
  return {
    type: payment.printReportAsync,
    auth,
    payload
  }
}

const Actions = {
  fetchData,
  fetchPaymentDetail,
  printReport,
  fundWallet
}

export default Actions;
