

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { CounterBox, DashboardChart, APIProviderStatus, TransactionHistoryTable, Loading } from '../../common/components';
import { VSpace } from '../../common/components/styles';
import { Icons, Themes } from '../../common/constants';

import { Row, Col } from 'react-flexbox-grid';
import { Util } from '../../util';

import { dashboardActions, dashboardConstants } from "../../state/dashboard";

const { fetchData } = dashboardActions;

const Dashboard = ({ dataFetched, fetchData, auth }) => {

  const { data: dashData, loading: dataFetchedLoading } = dataFetched;

  useEffect(() => {
    if (!auth.loading && !auth.refresh_token)
        fetchData(auth);
  }, [fetchData, auth]);

    return(
        <>
                <Loading
                    background={Util.hexToRgba(Themes.black, 0.01)}
                    loading={dataFetchedLoading}
                    showLogo={false}
                    iconColor={Themes.secondary}
                />
                <Row> 
                    <Col xs={12} sm={4} md={3}>
                        <CounterBox
                            type={'success'}
                            title={'Total Revenue'}
                            titleProps={{
                                className: 'counter-box__title'
                            }}
                            label={Util.formatAmount(dashData?.total_revenue, dashData?.currency)}
                            icon={<Icons.Revenue color={Themes.success} size={20} />}
                           
                        /> 
                    </Col>

                    <Col xs={12} sm={4} md={3}>
                        <CounterBox
                            type={'warning'}
                            title={'Total Transactions'}
                            titleProps={{
                                className: 'counter-box__title'
                            }}
                            label={dashData?.transaction_counts}
                            icon={<Icons.TransactionDArrow color={Themes.warning} size={20} />}
                        /> 
                    </Col>

                    <Col xs={12} sm={4} md={3}>
                        <CounterBox
                            type={'success'}
                            title={'Total Customers'}
                            titleProps={{
                                className: 'counter-box__title'
                            }}
                            label={dashData?.customer_counts}
                            icon={<Icons.Users color={Themes.success} size={20} />}
                        /> 
                    </Col>

                    <Col xs={12} sm={4} md={3}>
                        <CounterBox
                            type={'danger'}
                            title={'Service Providers'}
                            titleProps={{
                                className: 'counter-box__title'
                            }}
                            label={dashData?.provider_counts}
                            icon={<Icons.Gateway color={Themes.danger} size={20} />}
                        /> 
                    </Col>
                </Row>
                
                <VSpace space="20px" />
                <Row>
                    <Col xs={12} md={9}>
                        <DashboardChart />
                        <VSpace space="20px" />
                    </Col>
                    <Col xs={12} md={3}>
                        <APIProviderStatus />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <TransactionHistoryTable />
                    </Col>
                </Row>
        </>
    );
}

const structuredSelector = createStructuredSelector({
    auth: (state) => state.auth,
    dataFetched: (state) => state?.app[dashboardConstants.fetchData]?? {}
  });
  
const mapDispatchToProps = (dispatch) => {
    return {
      fetchData: (state) => dispatch(fetchData(state))
    };
};
  
export default connect(structuredSelector, mapDispatchToProps)(Dashboard);