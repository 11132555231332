import React from 'react';
import { Layout, Space, Typography } from 'antd';
import SidebarMenu from './sidebar_menu';
import { Block, Logo } from './styles';
import { Images, Themes } from '../constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { sidebarCollapsedAction } from '../../state/widget';

const { Sider } = Layout;
const { Text } = Typography;

const Sidebar = ({sidebar_collapsed, sidebarCollapsed, ...rest}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const onMenuClicked = (item) => {
    navigate(item.key, {replace: false});
  }

  return(
    <Sider 
      theme='light' 
      width={276} 
      trigger={null}
      collapsible 
      collapsed={sidebar_collapsed}
      style={{
        height: '100%',
        minHeight: '100vh'
      }}
      {...rest}
    >
      <Block 
        variant='div' 
        className='logo'
        padding="20px 40px"
      >
         {
            sidebar_collapsed? 
              <Logo src={Images.logo_without_text}
                style={{marginLeft: '-20px', width: '40px'}}
              /> 
              : 
              <Logo src={Images.logo_dark} style={{marginTop: '4px', width: '150px'}} />
         }

      </Block>
      <SidebarMenu 
        mode="inline"
        defaultSelectedKeys={[pathname]}
        style={{ height: 'auto', borderRight: 0, backgroundColor: Themes.transparent }}
        className={`sidebar-menu${sidebar_collapsed? ' has-collapsed' : ''}`}
        onClick={onMenuClicked}
      />

      {!sidebar_collapsed && <Block variant='div' className='sidebar-menu__bottom'>
        <Space direction='vertical' size={2}>
          <Text>Last login: </Text>
          <Text>20/12/2021 @ 4:47PM</Text>
        </Space>
      </Block>}

    </Sider>
  );
}


const structuredSelector = createStructuredSelector({
  auth: state => state.auth,
  sidebar_collapsed: state => state.widget.sidebar_collapsed
})

const mapDispatchToProps = (dispatch) => {
  return { 
     sidebarCollapsed: (collapsed) => dispatch(sidebarCollapsedAction(collapsed)),
  }
}


export default connect(structuredSelector, mapDispatchToProps)(Sidebar);
