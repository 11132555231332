import { Form } from "antd";
import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { PublicLayout } from "../../common/components";
import {
  Input,
  Heading,
  VSpace,
  FormGroup,
  Anchor,
  Button,
  Block
} from "../../common/components/styles";
import { Themes } from "../../common/constants";
import { app } from "../../config/app";
import { Paths } from "../../routes/paths";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate(Paths.reset_password);
  };

  return (
    <PublicLayout>
      <Heading className='text-center' color={Themes.secondary} size="24px" weight={500}>
        Forgot your Password?
      </Heading>
      <VSpace space="20px" />

      <Heading size='14px' color={Themes.dark} linespacing='22px' className='text-center'>
        To reset your password, enter the email address that you used to set up your <Anchor href={Paths.login} style={{color: Themes.secondary, fontWeight: '600'}}>{app.company.name}</Anchor> account. We'll send you a link to help you get back into your account. 
      </Heading>
      <VSpace space="20px" />

      <Form onFinish={handleSubmit}>
        <FormGroup direction="column">
          <Input
            labelProps={{
              text: "Email Address",
              htmlFor: "email"
            }}
            inputProps={{
              name: "email",
              id: "email",
              placeholder: ""
            }}
          />
        </FormGroup>

        <FormGroup className="mt-3">
          <Button secondary fullWidth primary cursor="true" type='submit'>
            Send
          </Button>
        </FormGroup>
      </Form>

      
      <Block margin="1.5rem 0rem" variant="p" className="text-center">
          <Anchor className="wt-500" color={Themes.gray} href={Paths.login}>
            Back to Login
          </Anchor>
      </Block>
    </PublicLayout>
  );
};

const structuredSelector = createStructuredSelector({
  auth: state => state.auth
});

const mapDispatchToProps = {};

export default connect(structuredSelector, mapDispatchToProps)(ForgotPassword);
