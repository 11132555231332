import React from 'react';
import { Col } from 'react-flexbox-grid';

const Info = ({title, description, xs, sm, md, children, className}) => {
    const mergedClassNames = 'py-2 px-1 ' + className;

    return (
        <Col xs={xs} sm={sm} md={md} className={mergedClassNames}>
            {children? children : <>
                <h1 className='info__title'>
                    {title}
                </h1>
                <h3 className='info__description mt-1'>{description}</h3>
            </>}
        </Col>
    )
}

export default Info;