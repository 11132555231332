import { transaction } from '../../common/constants'


const fetchData = (auth, pageNumber) => {
  return {
    type: transaction.fetchData,
    payload: {
      auth, pageNumber
    }
  }
}

const fetchTransactionDetail = (auth, txRef) => {
  return {
    type: transaction.fetchTransactionDetail,
    payload: {
      auth, txRef
    }
  }
}


const cancel = (auth, id) => {
  return {
    type: transaction.cancelTransactionAsync,
    auth,
    payload: {
      id
    }
  }
}

const downloadReport = (auth, id) => {
  return {
    type: transaction.downloadReportAsync,
    auth,
    payload: {
      id
    }
  }
}

const printReport = (auth, payload) => {
  return {
    type: transaction.printReportAsync,
    auth,
    payload
  }
}

const Actions = {
  fetchData,
  fetchTransactionDetail,
  cancel,
  downloadReport,
  printReport
}

export default Actions;
