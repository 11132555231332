const transaction = {
    fetchData: 'FETCH_TRANSACTION_DATA_REQUEST',
    fetchTransactionDetail: 'FETCH_TRANSACTION_DETAIL',
    cancelTransaction: 'CANCEL_TRANSACTION_REQUEST',
    downloadReport: 'DOWNLOAD_TRANSACTION_REPORT_REQUEST',
    cancelTransactionAsync: 'CANCEL_TRANSACTION_REQUEST_ASYNC',
    downloadReportAsync: 'DOWNLOAD_TRANSACTION_REPORT_REQUEST_ASYNC',
    printReport: 'PRINT_TRANSACTION_REPORT',
    printReportAsync: 'PRINT_TRANSACTION_REPORT_ASYNC',
    success: 'TRANSACTION_SUCCESS',
    error: 'TRANSACTION_ERROR',
    loading: 'TRANSACTION_LOADING'
};

export default transaction;