import { Constants } from "./constants";

const initialState = {
  //Dynamic properties
};

const Reducers = (state = initialState, action) => {
  switch (action.type) {
    case Constants.loading:
    case Constants.error:
        return {
          ...state,
          [action.actionName] : {
            ...state[action?.actionName],
            ...action.payload[action.actionName]
          }
    };
    case Constants.success:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

export default Reducers;
