import { all, call, put, takeLatest } from 'redux-saga/effects';
import complianceApi from './compliance.api';
import { compliance } from '../../common/constants';

import { NotificationManager } from 'react-notifications';
import { errorAsyncSaga } from '../common/sagas';
import { commonActions } from '../common/actions';


function* asyncFetchData(action) {
  yield put(commonActions.loading(compliance.fetchData));

  try {
    const response = yield call(complianceApi.fetchData, action.payload.auth, action.payload.pageNumber);
    yield put(commonActions.success(compliance.fetchData, response.data));
  }
  catch(err) {
    const errorData = err?.response?.data?? {message: 'An unknown error has occurred!'};
    yield call(errorAsyncSaga, {actionName: compliance.fetchData, error: errorData, currentPage: action.payload.pageNumber})
  }
}

export function* watchFetchDataSaga() {
  yield takeLatest(compliance.fetchData, asyncFetchData);
}


function* asyncApprove(action) {
  yield put(commonActions.loading(compliance.approve));

  try {
    const response = yield call(complianceApi.approve, action?.auth, action?.payload?.id);
    yield put(commonActions.success(compliance.approve, response.data));
    NotificationManager.success(response?.data?.message);
  }
  catch(err) {
    const errorData = err?.response?.data?? {message: 'An unknown error has occurred!'};
    yield call(errorAsyncSaga, {actionName: compliance.approve, error: errorData})
    NotificationManager.error(errorData?.message);
  }
}

export function* watchApproveSaga() {
  yield takeLatest(compliance.approveAsync, asyncApprove);
}

function* asyncDecline(action) {
  yield put(commonActions.loading(compliance.decline));

  try {
    const response = yield call(complianceApi.decline, action?.auth, action?.payload?.id);
    yield put(commonActions.success(compliance.decline, response.data));
    NotificationManager.success(response?.data?.message);
  }
  catch(err) {
    const errorData = err?.response?.data?? {message: 'An unknown error has occurred!'};
    yield call(errorAsyncSaga, {actionName: compliance.decline, error: errorData})
    NotificationManager.error(errorData?.message);
  }
}

export function* watchDeclineSaga() {
  yield takeLatest(compliance.declineAsync, asyncDecline);
}

function* asyncUploadDocs(action) {
  yield put(commonActions.loading(compliance.uploadDocs));
  try {
    const response = yield call(complianceApi.uploadDocs, action?.auth, action?.payload);
    yield put(commonActions.success(compliance.uploadDocs, response.data));
  }
  catch(err) {
    const errorData = err?.response?.data?? {message: 'An unknown error has occurred!'};
    yield call(errorAsyncSaga, {actionName: compliance.uploadDocs, error: errorData?.error})
    NotificationManager.error(errorData?.message);
  }
}

export function* watchUploadDocsSaga() {
  yield takeLatest(compliance.uploadDocsAsync, asyncUploadDocs);
}

export default function* ComplianceSagas() {
  yield all([
    watchFetchDataSaga(),
    watchApproveSaga(),
    watchDeclineSaga(),
    watchUploadDocsSaga()
  ])
}

