import { all, call, put, takeLatest } from 'redux-saga/effects';
import paymentApi from './payment.api';
import { payment } from '../../common/constants';
import { commonActions } from '../common/actions';
import { errorAsyncSaga } from '../common/sagas';


function* asyncFetchPaymentData(action) {
  yield put(commonActions.loading(payment.fetchData));
  try {
    const response = yield call(paymentApi.fetchPaymentData, action.payload.auth, action.payload.pageNumber);
    yield put(commonActions.success(payment.fetchData, response.data));
  }
  catch(err) {
    const errorData = err?.response?.data?? {message: 'An unknown error has occurred!'};
    yield call(errorAsyncSaga, {actionName: payment.fetchData, error: errorData, currentPage: action.payload.pageNumber});
  }
}

export function* watchFetchDataSaga() {
  yield takeLatest(payment.fetchData, asyncFetchPaymentData);
}

function* asyncFetchPaymentDetail(action) {
  yield put(commonActions.loading(payment.fetchPaymentDetail));
  try {
    const response = yield call(paymentApi.fetchPaymentDetail, action.payload.auth, action.payload.txRef);
    yield put(commonActions.success(payment.fetchPaymentDetail, response.data));
  }
  catch(err) {
    const errorData = err?.response?.data?? {message: 'An unknown error has occurred!'};
    yield call(errorAsyncSaga, {actionName: payment.fetchPaymentDetail, error: errorData});
  }
}

export function* watchFetchPaymentDetailSaga() {
  yield takeLatest(payment.fetchPaymentDetail, asyncFetchPaymentDetail);
}


//Manual Wallet Funding
function* asyncFundWallet(action) {
  yield put(commonActions.loading(payment.fundWallet));
  try {
    const response = yield call(paymentApi.fundWallet, action.payload.auth, action.payload.data);
    yield put(commonActions.success(payment.fundWallet, response.data));
  }
  catch(err) {
    const errorData = err?.response?.data?? {message: 'An unknown error has occurred!'};
    yield call(errorAsyncSaga, {actionName: payment.fundWallet, error: errorData});
  }
}

export function* watchFundWalletSaga() {
  yield takeLatest(payment.fundWallet, asyncFundWallet);
}


//Print Payment Report

function* asyncPrintReport(action) {
  yield put(commonActions.loading(payment.printReport));
  try {
    const response = yield call(paymentApi.printReport, action?.auth, action?.payload);
    yield put(commonActions.success(payment.printReport, response.data));
  }
  catch(err) {
    const errorData = err?.response?.data?? {message: 'An unknown error has occurred!'};
    yield call(errorAsyncSaga, {actionName: payment.printReport, error: errorData})
  }
}

export function* watchPrintReportSaga() {
  yield takeLatest(payment.printReportAsync, asyncPrintReport);
}



export default function* paymentSagas() {
  yield all([
    watchFetchDataSaga(),
    watchFetchPaymentDetailSaga(),
    watchFundWalletSaga()
  ])
}

