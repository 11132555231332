import { Paths } from "../routes/paths";

export const app = {
    apiBaseUrl: process.env.REACT_APP_API_URL,
    before_auth_path: Paths.login,
    after_auth_path: Paths.dashboard_overview,
    company: {
        name: 'Digital Output',
        url: 'digitaloutput.ng',
        full_url: 'https://digitaloutput.ng'
    }
}