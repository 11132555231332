
import axios from 'axios';

const connect = (authState = null) => {
    const config = {
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 5000,
        headers: {
            common: {'Authorization': `${authState?.token_type} ${authState?.access_token}`}
        }
    }
    const axiosInstance = axios.create(
        config
    );
    return axiosInstance;
}

export {connect};